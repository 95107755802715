.order-detials-page .od-bg {
    /* background: url("../../../../../assets/image/od-bg-1.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom; */
    background: rgb(25, 117, 201);
    background: linear-gradient(261deg, rgba(25, 117, 201, 1) 0%, rgba(96, 169, 235, 1) 41%);
    background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
}

.od-row {
    display: flex;
    align-items: stretch;
}

.od-col {
    background: #fff;
    /* min-height: 100px; */
    min-height: calc(100vh - 560px);
    /* border: 1px solid #ddd; */
    border-radius: 0.5rem;
    padding: 10px;
    box-shadow: 8px 8px 7px rgba(0, 0, 0, 0.1) !important;
    height: 100%;
}

.od-col h6 {
    font-weight: 700;
}

.od-status-lines p {
    background-color: gainsboro;
    padding: 4px 10px;
    font-size: 12px !important;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 10px;
    /* text-transform: capitalize; */
    margin-bottom: 0;
    font-weight: 700;
    white-space: nowrap;
}

.od-list {
    list-style: none;
    padding-left: 0;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.od-list li {
    display: flex;
    justify-content: space-between;
}

.od-list li span:first-of-type {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 35%;
    color: gray;
}

.od-list li span:last-of-type {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 65%;
}

.od-pd-list li span:first-of-type {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
}

.od-pd-list li span:last-of-type {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
}

/* 
.product-details-sec {
    height: calc(100vh - 33rem);
    overflow-y: auto;
} */

.product-details-sec .pd-total {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.product-details-sec .pd-total p {
    display: flex;
    justify-content: space-between;
    width: 500px;
}

.product-details-sec .pd-total p span:first-of-type {
    flex-basis: 80%;
}

.product-details-sec .pd-total p span:last-of-type {
    flex-basis: 20%;
}

.order-detials-page .anchor-awb {
    text-decoration: none;
    border-bottom: 2px dashed var(--sh-primary);
    width: fit-content;
    color: var(--sh-primary);
}

.order-detials-page .partner-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 0.5px;
    box-shadow: 8px 8px 7px rgba(0, 0, 0, 0.1) !important;
}

.order-detials-page .od-courier-details {
    display: flex;
    gap: 10px;
    align-items: center;
}

.order-detials-page .od-courier-details p {
    margin-bottom: 0;
    font-weight: 700;
}