ul [data-rbd-droppable-context-id] {
    list-style: none;
}

.courier-preference {
    height: calc(100vh - 174px);
    overflow-y: auto;
}

.courier-preference .courier-preference-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
}

.courier-preference .courier-preference-list .Weight-slab {
    border-radius: 0.5rem;
    padding: 10px;
    width: 100%;
    border: 1px solid var(--sh-primary-light);
    /* min-height: calc(100vh - 301px); */
}

.Weight-slab ul {
    list-style: none;
    height: calc(100vh - 373px);
    padding-left: 0;
    overflow-y: auto;
}

.Weight-slab ul li {
    padding: 7px 5px;
    border-radius: 0.5rem;
}

.Weight-slab ul li img {
    width: 50px;
    height: 50px;
    margin-inline: 10px;
}

.default-sorting-section {
    display: flex;
    gap: 6px;
    align-items: center;
    width: 100%;
    min-width: unset;
    justify-content: space-between;
}

.default-sorting-section label select {
    width: 270px;
    min-width: unset;
}

.cp-or-line {
    position: relative;
    margin-block: 35px;
}

.cp-or-line span {
    position: absolute;
    background: #fff;
    padding: 9px;
    border: 1px solid #87898b;
    border-radius: 50%;
    top: -21px;
    left: 50%;
}

.set-of-rules {
    display: flex;
    flex-direction: column;
    gap: 15px;
    counter-reset: section;
}

.minor-rule-row::before {
    counter-increment: section;
    content: counter(section);
    height: 36px;
    padding-inline: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--sh-primary-middle);
    border-radius: 0.5rem;
}

.major-rule-row label {
    display: flex;
    gap: 7px;
    align-items: center;
    white-space: nowrap;
}

.minor-rule-row {
    display: flex;
    gap: 10px;
}

.major-rule-row label input,
.major-rule-row label select,
.minor-rule-row input,
.minor-rule-row select {
    min-width: unset;
    width: 100%;
}

.priority-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-items: end;
    gap: 20px;
}

.priority-container label {
    /* padding: 5px; */
    width: 100%;
    position: relative;
}

.priority-container label select {
    width: 100%;
    margin-top: 5px;
}


.app {
    display: flex;
    justify-content: space-around;
    padding: 20px;
}

.pool,
.sequence {
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 300px;
    min-height: 400px;
    padding: 10px;
}

.couriers-list {
    /* display: grid; */
    /* grid-template-columns: auto auto auto; */
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.courier {
    padding: 5px 0px;
    background-color: var(--sh-primary-light);
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.courier img {
    width: 35px;
    height: 35px;
}