.notification-panel {
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    z-index: 1000;
    animation: slideIn 0.4s ease-out;
    border-left: 1px solid #eaeaea;
    overflow-y: auto;
}

.notification-header {
    background-color: #CEDBF2;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notification-header h3 {
    font-size: 18px;
    margin: 0;
}

.notification-header .btn-close {
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    width: fit-content;
    background: var(--sh-primary-light);
    color: var(--sh-primary)
}

.notification-header .btn-close:focus {
    outline: none;
}

.notification-tabs {
    display: flex;
    justify-content: space-around;
    background-color: #f8f8f8;
    border-bottom: 1px solid #eaeaea;
    padding: 10px 0;
}

.tab-button {
    background: none;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    color: #666;
    transition: color 0.3s ease;
}

.tab-button.active {
    color: #333;
    font-weight: bold;
    border-bottom: 2px solid #333;
}

.notification-panel .view-all-btn {
    font-weight: 600;
    /* text-decoration: underline; */
    color: var(--sh-primary);
    width: fit-content;
    text-align: center;
}

.notification-panel .view-all-btn:hover {
    text-shadow: 5px 3px 3px #5dabcd;
}

.notification-body {
    padding: 15px;
    flex-grow: 1;
    max-height: calc(100vh - 165px);
    overflow: auto;
}


.notification-item {
    margin-bottom: 15px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 6px solid #f9f9f9;

}

.notification-item span {
    font-size: 14px;
    color: #333;
}

.notification-item small {
    color: #999;
    font-size: 12px;
    white-space: nowrap;
}

/* 
.notification-item.whats-new {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
} */

.notification-item.whats-new h4 {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1.2rem;
    margin-block: 7px 0px;
    font-weight: 600;
}

.notification-item.whats-new img {
    width: 100%;
    height: 200px;
    border-radius: 0.5rem;
}

.notification-item .status-icon {
    cursor: pointer;
}

.notification-item .mail-icon-tooltip {
    left: unset;
    white-space: nowrap;
    min-height: unset;
    right: -20px;
    top: -25px;
    bottom: unset;
}

.unread-notification {
    border-left: 6px solid forestgreen;
}

.unread-notification .status-icon {
    color: forestgreen;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}