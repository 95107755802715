a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

.SideNav {
    width: 50px;
    height: 100%;
    max-height: 100vh;
    background-color: #FFFFFF;
    background-color: #8bb9e3;
    background-color: #c5dcf1;
    /* background-color: #0f3c5b; */
    color: white;
    transition: width 0.5s ease;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 4;
    font-weight: 400;
    box-shadow: 4px 0 8px rgba(0, 0, 0, 0.1);
}

.menu-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: clip;
    height: calc(100vh - 65px);
    padding-bottom: 20px;
    /* gap: 10px; */
    transition: height 1s linear;
}

.SideNav.expanded {
    width: 200px;
}

.logo-container img {
    height: auto;
    padding: 0 10px;
    margin: 20px 0;
    object-fit: contain;
    cursor: pointer;
    height: 24px;
}

.logo-container .full-logo {
    width: 187px;
}

.logo-container .mobile-logo {
    width: 51px;
}

#sidenav .nav-link {
    /* width: 176px; */
    /* color: #0F3C5B; */
    /* color: #fff; */
    cursor: pointer;
    padding-inline: 6px;
    /* margin-inline: 7px; */
    white-space: nowrap;
    padding-block: 7px;
    border-radius: 0.5rem;
}

#sidenav .nav-link:active span,
#sidenav .nav-link:focus span,
#sidenav .nav-link:hover span {
    /* background-color: #CEDBF2; */
    /* font-weight: 700; */
    /* color: #0F3C5B; */
    border-radius: 4px;
    /* border-left: 4px solid #0F3C5B; */
}

.sidebar-label-wrapper:focus-visible {
    outline: none;
}

.sidebar-label-wrapper svg {
    vertical-align: -7px;
}

.sidebar-label-wrapper a svg path,
.sidebar-label-wrapper .nav-link svg path {
    fill: #0F3C5B;
}

.sidebar-label-wrapper a:hover svg path,
#sidenav .nav-link.main:hover svg path {
    fill: #fff;
}

.sidebar-label-wrapper a:hover .submenu-icon svg path,
#sidenav .nav-link.main:hover .submenu-icon svg path {
    fill: #0F3C5B;
}

.sidebar-label-wrapper a.active svg path,
.sidebar-label-wrapper .nav-link.active svg path {
    fill: #fff;
}

.sidebar-label-wrapper a {
    padding: 10px 6px;
}

.sidebar-label-wrapper div {
    padding: 10px 6px;
}

.sidebar-label-wrapper a,
.nav-link {
    color: #0F3C5B;
}

.sidebar-label-wrapper a:hover,
.nav-link.main:hover .nav-link {
    color: #fff;
    background-color: #0F3C5B;
    border-radius: 0.5rem;
}

.sidebar-label-wrapper a.active,
.nav-link.active {
    /* background-color: #fff; */
    border-radius: 0.2rem;
    font-weight: 700;
    background: #0F3C5B;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: #fff;
}

.SideNav .dropdown-content {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-size: 0.9rem;
    padding-left: 1.9rem;
    animation: dropdown 0.4s linear;
    /* overflow-y: hidden; */
    /* overflow-x: hidden; */
    color: #0F3C5B;
    padding-top: 0.5rem;
}

.SideNav .dropdown-content a {
    width: 8rem;
    padding-block: 0.5rem;
}

.SideNav .dropdown-content a .submenu-icon {
    color: #0F3C5B;
}

.SideNav .dropdown-content a:hover {
    background-color: #0F3C5B;
    color: #fff;
    border-radius: 0.5rem;
}

#sidenav .nav-link.main .dropdown-content a:hover .submenu-icon svg path {
    fill: #fff;
}

.SideNav .dropdown-content.open {
    display: flex;
}

.SideNav .dropdown-content.open a {
    position: relative;
    padding-left: 10px;
    width: 100%;
}

/* .SideNav .dropdown-content.open a::before {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #0F3C5B;
    height: 45px;
    left: -12px;
    bottom: 19px;
} */

/* .SideNav .dropdown-content.open a:first-of-type::before {
    height: 27px;
} */

/* .SideNav .dropdown-content.open a::after {
    content: '';
    position: absolute;
    width: 15px;
    background-color: #0F3C5B;
    height: 1px;
    left: -12px;
    bottom: 19px;
} */


@keyframes dropdown {
    from {
        height: 0;
    }

    to {
        height: 100%;
    }
}


/* Width and color of the scrollbar track */
::-webkit-scrollbar {
    width: 4px;
    /* scrollbar-width: thin; */
    /* scrollbar-color: #f1f1f1 #888; */
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Style of the scrollbar handle */
::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
}

/* On hover, style the scrollbar handle */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    scrollbar-color: #f1f1f1 #555;
}

/* .nav-link.active {
    background-color: #CEDBF2;
    border-radius: 0.2rem;
    font-weight: 700;

} */

#sidenav .nav-link.main {
    padding-block: 2px;
}

/* #sidenav .logo-container {
    background: #fff;
    margin-bottom: 15px;
} */