.gst-invoicing-page .react-toggle-track {
    width: 34px;
    height: 17px;
}

.gst-invoicing-page .react-toggle-thumb {
    width: 12px;
    height: 12px;
    top: 2px;
    left: 3px;
}

.gst-invoicing-page .react-toggle--checked .react-toggle-thumb {
    left: 19px;
}