.subaccount-pop .modal-dialog {
    margin-top: 10%;
}

.subaccount-pop .modal-header {
    justify-content: start;
}

.subaccount-pop .modal-body {
    text-align: start;
}

.subaccount-pop .modal-footer {
    justify-content: end;
}

.add-account-panel {
    width: 30em;
    background: #fff;
    height: 100vh;
    position: fixed;
    top: 0px;
    right: -40em;
    transition: 0.6s;
    z-index: 6;
}

.add-account-panel.open {
    right: 0px;
}

.add-account-panel .panel-header {
    background-color: #cedbf2;
    padding: 15px 20px;
    width: 100%;
}

.add-account-panel .panel-body {
    padding: 20px 30px;
}

.manage-sub-accounts .channel-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 140px;
    gap: 5px;
}

.manage-sub-accounts .channel-list div {
    /* flex-grow: 1; */
    flex-shrink: 1;
    flex-basis: calc(25% - 10px);
    box-sizing: border-box;
    text-align: center;
}

.manage-sub-accounts .channel-list div img {
    cursor: pointer;
}

.manage-sub-accounts .copy-password,
.manage-sub-accounts .copy-key {
    font-size: 12px;
    width: 120px;
    padding-block: 2px;
    border-radius: 0.5rem;
    text-align: center;
    background: #ECF8EE;
    color: #3BB54B;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.manage-sub-accounts .copy-key {
    width: 90px;
}

.manage-sub-accounts .email-btn {
    font-size: 16px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    background: #ECF8EE;
    color: #3BB54B;
}

.manage-sub-accounts .pending-status {
    background: #FDF7C2;
    color: #A16418;
    border-radius: 0.5rem;
    width: 100px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 2px;
    gap: 3px;
}