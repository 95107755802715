.verified-customer .header-section {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 40px;
    font-size: 1.4rem;
    width: 100%;
    justify-content: space-between;
}

.verified-customer .shipease-logo {
    position: relative;
}

/* .verified-customer .header-section .shipease-logo,*/
.verified-customer .header-section .company-logo {
    border-radius: 1rem;
    overflow: hidden;
}

.verified-customer .header-section img,
.verified-customer .header-section img {
    height: auto;
    max-height: 50px;
    width: auto;
}

.verified-customer .header-section hr {
    width: 25px;
    height: 1px;
    border: none;
    background: #fff;
    border-bottom: 2px dashed var(--sh-primary-middle);
}

.verified-customer .details-title {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}

.verified-customer .follow-details {
    display: grid;
    column-gap: 100px;
    flex-wrap: wrap;
    row-gap: 40px;
    grid-template-columns: repeat(4, 1fr);
    background: #eff6fb;
    padding: 20px;
    border-radius: 10px;
}

.verified-customer .detail-label {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    color: #696969;
}

.verified-customer .detail-label span {
    color: #000;
    font-size: 1rem;
    /* white-space: nowrap; */
}

.verified-customer .follow-details a {
    text-decoration: none;
    background: #fff;
    padding: 5px 10px;
    border: 1px solid var(--sh-primary);
    border-radius: 0.5rem;
    width: fit-content;
    font-size: 0.9rem;
    color: var(--sh-primary);
    cursor: pointer;
}

.verified-customer .follow-details a svg {
    vertical-align: -1px;
}

.verified-customer .follow-details a:hover {
    background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
    color: #fff;
}

.details-container hr {
    margin-block: 40px;
}