:root {
  --sh-primary: #1975C9;
  --sh-primary-middle: #60a9eb;
  --sh-primary-light: #c5dcf1;
  --sh-ddd: #dddddd;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  /* background-color: #CEDBF2 !important; */
  /* background-color: #deebf7 !important; */
  background-color: #eaf2fa !important;
  font-family: "Roboto", sans-serif;
}

html::-webkit-scrollbar {
  width: 2px;
  height: 20px;
}

html::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 3px;
}

html::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 3px;
}

p {
  margin-bottom: 0.5rem;
}

/*Figma Ui*/

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px !important;
}

.p15 {
  padding: 15px;
}

.p10 {
  padding: 10px;
}

.p-y {
  padding: 5px 0px;
}

.p0 {
  padding: 0%;
}

.mr-10 {
  margin-right: 10px;
}

.mt15 {
  margin-top: 15px;
}

.m0 {
  margin: 0;
}

.bg-red {
  background: #F31429 !important;
}

.text-sh-primary {
  color: var(--sh-primary);
}

.text-sh-red {
  color: #FC3B3B;
}

.bg-sh-primary {
  background: var(--sh-primary) !important;
}

.bg-sh-primary-light {
  background: var(--sh-primary-light) !important;
}

.text-gray {
  color: #727986;
}

.text-ddd {
  color: var(--sh-ddd);
}

.text-gray-light {
  color: #9D9595;
}

.font10 {
  font-size: 10px;
}

.font11 {
  font-size: 11px;
}

.font12 {
  font-size: 12px;
}

.font13 {
  font-size: 13px;
}

.font15 {
  font-size: 15px;
}

.font30 {
  font-size: 30px;
}

.font20 {
  font-size: 20px;
}

.font40 {
  font-size: 40px;
}

.font20r {
  font-size: 1.25rem;
}

.title-text {
  color: #0B3B5B;
}

.text-blue {
  color: #1975C9;
}

.text-blue-dark {
  color: #0B3B5B;
}

.text-purple {
  color: #845ADF;
}

.text-golden {
  color: #FFD700;
}

.text-red {
  color: #F31429;
}

.text-white {
  color: #fff;
}

.text-aqua {
  color: #3EC7E8;
}

.text-green {
  color: #3BB54B;
}

.text-yellow {
  color: #F6B954;
}

.bold-600 {
  font-weight: 600;
}

.mt50 {
  margin-top: 50px;
}

.circle50 {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.pt20 {
  padding-top: 1.25rem;
}

.w50 {
  width: 50%;
}

.w15 {
  width: 15%;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.progress,
.progress-stacked {
  background: #E0E0E0;
  height: 4px;
}

.progress-bar {
  background-color: inherit;
}

.bg-blue {
  background-color: #0B3B5B;
}

.bg-blue-dark {
  background-color: #0B3B5B;
}

.bg-red {
  background-color: #F31429;
}

.bg-green {
  background-color: #3BB54B;
}

.bg-orange {
  background-color: #F6B954;
}

.bg-aqua {
  background-color: #3EC7E8;
}

.bg-purple {
  background-color: #845ADF;
}

.bg-pink {
  background-color: #FF2E92;
}

.grid {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.grid .g-col-6 {
  grid-column: auto/span 6;
}

.pr0 {
  padding-right: 0px;
}

.title {
  font-size: 18px;
  color: #0B3B5B;
  padding: 0px;
  margin: 0px;
  font-weight: 500;
  margin-bottom: 15px;
}

.tab-btn-ui {
  --bs-btn-color: #5C66E9;
  --bs-btn-bg: #EEEFFD;
  --bs-btn-border-color: #DFE1FB;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5C66E9;
  font-weight: 600;
  --bs-btn-padding-x: 0.50rem;
  color: var(--bs-btn-color);
  --bs-btn-font-size: 0.7rem;
}

.list-ui {
  padding: 0px;
  list-style: none;
}

.list-ui li {
  display: grid;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 600;
  grid-template-columns: 55% 10% 35%;
}

.cardvalue {
  display: flex;
  justify-content: end;
}

.list-ui li:last-of-type {
  margin-bottom: 0;
}

.list-ui li p {
  margin: 0px;
}

.bg-red-light {
  background-color: #FCEAEA;
}

.bg-orange-light {
  background-color: #fdf2df;
}

.bg-green-light {
  background-color: #ECF8EE;
}

.bg-blue-light {
  background-color: #E8F2FB;
}

.bg-purple-light {
  background-color: #F3EFFD;
}

.bg-sky-light {
  background-color: #ECFAFD;
}

.round-8 {
  border-radius: 8px;
}

.x5-y10 {
  padding: 5px 10px;
}

.box-shadow {
  border-radius: 0.5rem;
  background: #fff;

}

.form-select:focus {
  border-color: #86b7fe;

  box-shadow: inherit;
}

/* .table-ui {} */

.table-ui th {
  background: #E0F0FA;
}

.table-ui tbody,
.table-ui td,
.table-ui tfoot,
.table-ui th,
.table-ui thead,
.table-ui tr {
  font-size: 12px;
}

/* .dropdown-toggle-ui {} */

.dropdown-toggle-ui::after {
  display: none;
}

.dropdown-menu-ui {
  font-size: 14px;
}

/* .dropdown-item-ui {} */

.dropdown-item-ui:hover {
  background-color: #e8f2fb;
  color: #1975c9;
}

.table {
  --bs-table-hover-bg: #f3effd;
}

.table td {
  vertical-align: middle;
}

.round-full {
  border-radius: 100%;
}

.line-hig0 {
  line-height: 0;
}

.btn-red-light {
  background-color: #FCEAEA !important;
  color: #F31429 !important;
  border: #FCEAEA !important;
}

.btn-red-light:hover,
.btn-red-light:active {
  background-color: #F31429 !important;
  color: #FCEAEA !important;
  border: #F31429 !important;
}


.list-ui-point {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

/* .list-ui-point:before {
  content: "";
  display: inline-block;
  width: 1px;
  background: #d3d3d3;
  position: absolute;
  left: 3px;
  top: 5px;
  height: calc(100% - 10px);
} */



.list-ui-point li {
  position: relative;
  padding-left: 0px;
  margin-bottom: 15px;
}

/* 
.list-ui-point li:before {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background: rgb(3, 44, 3);
  position: absolute;
  left: -2px;
  top: 20px;
  border-radius: 10px;
}



.list-ui-point li:nth-child(1):before {
  background: #5964FF;
}

.list-ui-point li:nth-child(2):before {
  background: #FF5964;
}

.list-ui-point li:nth-child(3):before {
  background: #27BF94;
}

.list-ui-point li:nth-child(4):before {
  background: #FDA005;
}

.list-ui-point li:nth-child(5):before {
  background: #3EC7E8;
}

.list-ui-point li:nth-child(6):before {
  background: #845ADF;
} */


.avatar {
  position: relative;
  width: 2.5rem;
  display: inline-block;
  height: 2.5rem;
  border-radius: 100%;
}

.avatar img {
  height: 100%;
  border-radius: 100%;
}


.list-none {
  list-style: none;
}

.cardsSpace {
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 0;
}

.cardsSpace>div {
  height: 100%;
}

.cardsSpace>.grid.gap-3 {
  height: auto;
  gap: 20px !important;
}

.p10-inline {
  padding-inline: 10px;
}

.p7 {
  padding: 7px;
}

.card-height {
  height: 132px;
}

.ws-nowrap {
  white-space: nowrap;
}

.text-end {
  text-align: end;
}

.gap-10 {
  gap: 10px;
}

:root .btn:active {
  border-color: transparent;
  color: #fff;
}

:root .btn:focus {
  box-shadow: none;
}

.main-button {
  /* background-color: #1975C9 !important; */
  background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
  color: #fff;
  padding: 5px 10px;
  white-space: nowrap;
  border: 0;
}

.main-button:hover {
  /* background-color: #1975C9 !important; */
  color: #fff;
}

.main-button-outline:focus-visible,
.main-button-outline:active,
.main-button-outline {
  border-color: #1975C9;
  color: #1975C9;
  /* background-color: #fff; */
  padding: 5px 10px;
  white-space: nowrap;
}

.main-button-outline:hover {
  /* background-color: #1975C9 !important; */
  background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
  color: #fff;
}

.cancel-button,
.cancel-button:hover {
  padding: 5px 10px;
  white-space: nowrap;
  background-color: rgb(240, 240, 240);
}

.delete-btn,
.delete-btn:hover {
  background-color: #fddcdf;
  color: #f31429;
  padding: 5px 10px;

}

.download-button,
.download-button:hover {
  background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
  color: #fff;
  width: 1.875rem;
  height: 1.875rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.375rem;
}

.downlaod-tooltip {
  display: flex;
  min-height: unset;
  height: 24px;
  padding: 0;
  width: 68px;
  top: -7px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}

.white-btn,
.white-btn:hover {
  background-color: #fff;
  color: var(--sh-primary);
  padding: 5px 10px;
}

.edit-btn {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
  color: #fff;
  justify-content: center;
  padding: 5px 10px;
  width: max-content;
}

.edit-btn:hover {
  color: #fff;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: 0 0px 2px 1px rgba(13, 110, 253, .25);
}

.input-field,
.select-field {
  height: 36px;
  border: 1px solid var(--sh-primary-middle);
  width: 100%;
  padding-left: 10px;
  border-radius: 0.5rem;
  background: #fff;
}

.select-field-account {
  height: 33px;
  border: 1px solid var(--sh-primary-middle);
  width: 100%;
  padding-left: 10px;
  border-radius: 0.5rem;
  background: #fff;
}

.inputs-container .input-field,
.inputs-container .select-field {
  min-width: 100%;
  width: auto;
}

.input-checkbox {
  border: 1px solid var(--sh-primary-middle);
  border-radius: 0.5rem;
}

.select-field * {
  border-radius: 0;
}

input:focus-visible,
select:focus-visible,
.input-fields:focus-visible,
.select-fields:focus-visible {
  outline: none;
}

input::placeholder {
  font-size: 1rem;
}

.seconadary-button {
  background-color: grey;
  color: #fff;
}

.status-field-container {
  position: relative;
}

.status-open {
  color: #3BB54B;
}

.status-closed {
  color: #F31429;
}

.status-progress {
  color: #F6B954;
}


.circle-blink::after {
  content: '';
  position: absolute;
  color: inherit;
  width: 1rem;
  height: 1rem;
  left: 1px;
  border-radius: 50px;
  margin-top: 3px;
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.status-open span::after {
  background-color: #3BB54B;
}

.status-closed span::after {
  background-color: #F31429;
}

.status-progress span::after {
  background-color: #F6B954;
}

.white-block {
  min-height: calc(100vh - 168px);
  /* height: 100%; */
  overflow-y: auto;
}

.column-container {
  height: 100%;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm,
div:where(.swal2-container) div:where(.swal2-actions):not(.swal2-loading) .swal2-styled:hover {
  background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: rgb(240, 240, 240);
  color: inherit;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel:hover {
  background: none !important;
  background-color: #6e7881 !important;
  color: #fff;
}

.shadow-sm {
  box-shadow: 8px 8px 7px rgba(0, 0, 0, 0.1) !important;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track,
.react-toggle-track {
  background-color: #ddd;
}

.react-toggle--checked .react-toggle-track,
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--sh-primary);
}

.react-toggle--checked .react-toggle-track-check,
.react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  border: none;
}

.reset-btn {
  color: var(--sh-primary);
  border-radius: 0;
  padding: 0;
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-error {
  position: absolute;
  bottom: -18px
}


label .css-b62m3t-container .css-1nmdiq5-menu {
  z-index: 2;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  border-radius: 0.5rem;
  padding: 0 0 15px;
}

div:where(.swal2-container) .swal2-html-container {
  font-size: 1rem;
  margin-top: 15px;
}

div:where(.swal2-container) div:where(.swal2-actions) {
  margin-top: 15px;
}

div:where(.swal2-icon).swal2-success {
  order: 4;
  margin-block: 20px 15px;
}

.swal2-title.custom-title {
  font-weight: 500;
  font-size: 1.25rem;
  padding: 15px;
  background: var(--sh-primary-light);
  border-radius: 0.5rem 0.5rem 0 0;
  color: var(--bs-heading-color);
}

input:disabled {
  /* background-color: #c5dcf145; */
  background-color: #fff;
  opacity: 0.3;
}

.react-datepicker__header {
  background-color: var(--sh-primary-light);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #000000;
}

.btn.main-button:disabled {
  color: #fff;
  border: none;
}

.table-container::-webkit-scrollbar {
  height: 2.5px;
}

.table-container td {
  white-space: nowrap;
}

.confirmation-modal .modal-dialog {
  margin-top: 20%;
}

.confirmation-modal .modal-header {
  font-weight: 500;
  padding: 15px;
  background: var(--sh-primary-light);
  border-radius: 0.5rem 0.5rem 0 0;
  color: var(--bs-heading-color);
  border-bottom: none;
  justify-content: center;
}

.confirmation-modal .modal-header .modal-title {
  font-size: 1.25rem;
}

.confirmation-modal .modal-footer {
  border-top: none;
  justify-content: center;
}

.confirmation-modal .modal-body {
  margin-block: 15px 3px;
  padding: 0;
  text-align: center;
}

.confirmation-modal .modal-dialog {
  max-width: 448px;
}

.table-responsive::-webkit-scrollbar {
  height: 2px;
}


img {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

[data-truncate-name] {
  display: inline-block;
  max-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.select-error{
  border: 1px solid red;
  border-radius: 5px;
}

.feature-disabled{
  pointer-events: none;
  opacity: 0.5;
}