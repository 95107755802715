.customer-details-container {
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: var(--bs-box-shadow-sm) !important;
    padding: 30px 15px;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.customer-details-form .details-form-row {
    padding-inline: 15px;
    align-items: center;
}

.customer-details-form label {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

/* .customer-details-form label input,
.customer-details-form label select {
    margin-top: 5px;
    width: 400px;
    min-width: 400px;
} */

/* .input-field{
    border: 2px solid red;
} */

.customer-details-form input[type="file"] {
    text-align: left;
    padding-left: 5px;
    padding-top: 6px;
}

.customer-details-form hr {
    border: 0;
    height: 1px;
    width: 100%;
    position: relative;
    margin: 1.875rem auto;
    background: var(--sh-primary);
}

.logo-file-upload {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 120px;
    border-radius: 4px;
    border: 1px dashed #383838;
}

.logo-file-upload input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.logo-file-upload .upload-logo-input {
    position: absolute;
    padding: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-file-upload .upload-logo-input span {
    font-size: 1.25rem;
    white-space: nowrap;
    margin-top: 10px;
}

.logo-file-upload .upload-logo-input span .upload-icon {
    font-size: 1.875rem;
}


.eye-button {
    background-color: transparent;
    border: none;
    position: absolute;
    z-index: 1000;
    bottom: 4px;
    right: 4px;
    cursor: pointer;
}

.pdf-preview-section {
    position: fixed;
    top: 15%;
    left: 15%;
    z-index: 6;
    width: 70%;
    height: 70%;
}

.add-account-text {
    color: var(--sh-primary);
}

.upload-doc-list {
    list-style: none;
}

.upload-doc-list li {
    display: flex;
    align-items: center;
}

.upload-doc-list li .delete-btn {
    display: flex;
    align-items: center;
    background-color: #F31429;
    color: #fff;
    justify-content: center;
    padding: 5px 10px;
}

.upload-doc-list li .delete-btn:hover {
    background-color: #F31429;
    color: #fff;
}

.agreement-section {
    width: 95%;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.Sign-section {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}

.Sign-section span {
    min-width: 5rem;
    border-bottom: 1px solid;
    display: inline-block;
}


.agreement-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.customer-details-form .preview-btn {
    /* position: absolute; */
    right: 1px;
    bottom: 0px;
    padding: 0.3125rem;
}

.input-box-disable{
  pointer-events: none;
  opacity: 0.7;
}
.input-box-enable{
    pointer-events:all;
    opacity: 1;
  }