.orders-menu-header ul {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  padding: 0;
}

.orders-menu-header ul li {
  padding: 5px 7px;
  color: rgba(0, 0, 0, .55);
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.anchor-order {
  text-decoration: underline;
  color: var(--sh-primary);
  position: relative;
  cursor: pointer;
  max-width: 135px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.anchor-error {
  color: #1975C9;
  position: relative;
  cursor: pointer;
}

.orders-menu-header .form-select {
  width: 320px;
  color: rgba(0, 0, 0, .55);
  background-color: #fff;
  border-radius: 0.2rem;
  /* font-weight: 700; */
  font-size: 16px;
  padding: 5px 7px;
  border-radius: 4px;
}

.orders-menu-header .form-select * {
  padding-block: 15px;
}

.down-sliding-select {
  position: relative;
  width: 12.5rem;
}

.selected-option {
  padding: 5px 7px;
  border: 1px solid #9ac2e5;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #111;
  border-radius: 0.5rem;
}

.options-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  z-index: 2;
  background: #fff;
}

.options-container.open {
  max-height: fit-content;
}

.option {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  font-size: 14px;
}

.option:hover {
  background-color: #f0f0f0;
}

/* .option.selected {
    background-color: #1975C9;
    color: #fff;
  } */

/* .down-sliding-select:hover .options-container {
    max-height: 100px; 
  } */



.all-orders td {
  background-color: #fff;
}


.Searchbox-label {
  position: relative;
}

.Searchbox-label .search-icon {
  position: absolute;
  top: 7px;
  left: 5px;
}

.Searchbox-label input {
  padding-left: 26px;
}

.orders-section-tabs {
  position: relative;
}

.orders-section-tabs .table-container {
  height: calc(100vh - 302px);
  overflow-y: auto;
}

.orders-section-tabs .table-container.m-table-height {
  height: calc(100vh - 222px);
}

.sticky-header {
  position: sticky;
  top: 0;
  /* background-color: #ffffff; */
  z-index: 1;
}

.table-row td,
.table-row th {
  background-color: #fff;
  padding: 0.5rem;
  border-bottom: none;
}

.table-row .checkbox-cell {
  min-width: 10px;
}

.table-row td {
  font-size: 14px;
}

.table-row td:first-child,
.table-row th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.table-row td:last-child,
.table-row th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.blank-row td {
  padding: 7px;
}

/* tbody tr.table-row:nth-of-type(4n-3) td{
  background-color: rgb(25 117 201 / 52%);
 } */


.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.search-container {
  width: 50%;
}

.search-container label {
  position: relative;
  width: 100%;
}

.search-container label input {
  padding-right: 40px;
  padding-left: 110px;
}

.search-container input:focus-visible {
  outline: none;
}

.search-container.ot-filters label button {
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
  cursor: pointer;
  background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
  color: #fff;
  width: 36px;
  height: 36px;
  border-radius: 0 0.5rem 0.5rem 0;
}

.search-container button.btn.main-button.dropdown-toggle.dropdown-toggle-split.show {
  border-color: var(--sh-primary);
  color: #fff;
}

.search-container.ot-filters .css-b62m3t-container {
  position: absolute;
  border: none;
  top: 1px;
  left: 1px;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  /* color: var(--sh-primary); */
  background: transparent;
  width: 110px;

}

.search-container.ot-filters .css-13cymwt-control {
  border: none;
  border-radius: 0.5rem;
  min-height: unset;
  background: transparent;
}

.search-container.ot-filters .css-t3ipsp-control,
.search-container.ot-filters .css-t3ipsp-control:hover {
  border: none;
  box-shadow: unset;
  background: transparent;
  min-height: unset;
}

.search-container.ot-filters .btn-group ul.dropdown-menu li {
  padding: 5px 10px;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}

.search-container.ot-filters .btn-group ul.dropdown-menu li:hover {
  background: #DEEBFF;
}

.search-container.ot-filters .btn-group ul.dropdown-menu li.active {
  background: #2684FF;
  color: #fff;
}

.css-t3ipsp-control:hover {
  min-height: unset;
}

.search-container p {
  margin-bottom: 0;
}

.checkbox-cell input {
  vertical-align: -1px;
}

.filter-container .button-container {
  display: flex;
}

.side-panel {
  position: fixed;
  top: 0;
  right: -50em;
  transition: 1s;
  height: 100vh;
  width: 30em;
  background-color: #fff;
  z-index: 6;
}

.backdrop {
  position: fixed;
  background-color: #111111b8;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5;
}

.side-panel.open {
  right: 0;
}

.cell-inside-box {
  display: flex;
  flex-direction: column;
}

.cell-inside-box p {
  margin-bottom: 0;
  font-size: 14px;
}

.cell-inside-box .details-on-hover.anchor-awb {
  text-decoration: none;
  border-bottom: 2px dashed var(--sh-primary);
  width: fit-content;
  text-transform: uppercase;
}

.cell-inside-box .product-details,
.cell-inside-box .details-on-hover {
  text-decoration: underline;
  color: #1975C9;
  position: relative;
  cursor: pointer;
  width: fit-content;
  text-transform: lowercase;
}


.cell-inside-box .product-details>span,
.cell-inside-box .details-on-hover>span {
  display: none;
  padding: 2px 10px;
  text-decoration: none;
  color: #111;
  font-size: 14px;
  position: absolute;
  background-color: #fff;
  bottom: 0px;
  left: 14px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
  width: auto;
  z-index: 6;
  max-height: 67px;
  overflow-y: auto;
  text-transform: lowercase;
  width: 250px;
  overflow-x: hidden;
  white-space: break-spaces;
}

.cell-inside-box .details-on-hover span {
  text-transform: capitalize;
}

.cell-inside-box .product-details>span:after,
.cell-inside-box .details-on-hover>span:after {
  content: '';
  position: absolute;
  bottom: 40px;
  left: -4%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  transform: rotate(270deg);
}

.cell-inside-box .product-details:hover>span,
.cell-inside-box .details-on-hover:hover>span {
  display: block;
}


.cell-inside-box .details-on-hover.extra>span {
  padding: 13px;
  text-decoration: none;
  color: #111;
  font-size: 11px;
  position: absolute;
  background-color: #fff;
  bottom: -111px;
  left: 0;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
  width: auto;
  z-index: 6;
}

.cell-inside-box .product-details span hr,
.cell-inside-box .details-on-hover>span hr {
  margin-block: 4px;
}

.cell-inside-box .details-on-hover>span hr:last-of-type {
  display: none;
}

.cell-inside-box .details-on-hover.extra>span:after {
  content: '';
  position: absolute;
  bottom: 108px;
  left: 29px;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  transform: rotate(0deg);
}

.cell-inside-box .verified-hover {
  max-width: unset;
  min-width: unset;
  max-height: unset;
  min-height: unset;
  height: auto;
  width: auto;
  font-weight: 700;
}

.order-Status-box {
  background-color: #c5dcf1;
  padding: 4px 10px;
  color: #1975C9;
  font-size: 12px !important;
  width: fit-content;
  border-radius: 10px;
  text-transform: capitalize;
  margin-bottom: 0;
  font-weight: 700;
  white-space: nowrap;
}

.status-box .order-Status-box {
  width: 100%;
  text-align: center;
}

.icon-rotate {
  transform: rotate(180deg);
}

.width-eclipse {
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.action-options {
  position: relative;
}

.action-options .threedots-img {
  justify-content: center;
  display: flex;
  background-color: #c5dcf1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.action-options .threedots-img img {
  transform: rotate(90deg);
  transition: 0.3s;
}

.action-options .action-list {
  transition: 0.3s;
  max-height: 0;
  position: absolute;
  right: 22px;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
  padding: 0px;
}

.action-options .action-list.middle {
  top: calc(-50% - 15px);
}

.action-options .action-list.above {
  bottom: calc(100% - 10px);
}

.action-options .action-list.below {
  top: calc(100% - 30px);
}

.action-options .action-list ul li.action-hr {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 40%, rgba(221, 221, 221, 1) 50%, rgba(221, 221, 221, 1) 60%, rgba(255, 255, 255, 1) 100%);
  height: 1px;
  padding-block: 0;
  margin-block: 8px;
}

.action-options .action-list ul li:hover {
  background-color: var(--sh-primary-light);
}

.action-options .action-list ul li.action-hr:hover {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 40%, rgba(221, 221, 221, 1) 50%, rgba(221, 221, 221, 1) 60%, rgba(255, 255, 255, 1) 100%);
  cursor: default;
}

.action-options.bulk-actions {
  z-index: 2;
}

.action-list ul {
  list-style: none;
  padding-left: 0px;
}

.action-list ul li {
  padding-block: 4px;
  cursor: pointer;
  padding-left: 10px;
}

.action-list ul li hr {
  margin-block: 0;
  margin-left: -8px;
  width: 127px;
}

.action-options:hover img {
  transform: rotate(0deg);
}

.action-options:hover .action-list {
  max-height: 361px;
  background-color: #fff;
  width: 150px;
  z-index: 1;
}

.info-missing-content {
  color: #F31429;
}

.ba-popup-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 5;
}


.add-tag-container {
  position: fixed;
  background-color: #fff;
  width: 40%;
  height: 40%;
  z-index: 6;
  top: 20%;
  left: 30%;
}

ul.order-label-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 5px;
  white-space: pre;
}

ul.order-label-list li {
  padding: 2px 5px;
  border: 1px solid var(--sh-primary-middle);
  border-radius: 0.5rem;
  white-space: nowrap;
}

.cell-inside-box .Labels-pool {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.cell-inside-box.shipping-details {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
}

.cell-inside-box.shipping-details img {
  height: 35px;
  width: 35px;
}

.awb-tracking-slider {
  position: fixed;
  width: 40em;
  height: 100vh;
  top: 0;
  right: -50em;
  background: #fff;
  transition: 1s;
  z-index: 6;
}

.awb-tracking-slider.open {
  right: 0;
}

.mps-flag {
  font-size: 11px;
  /* border: 1px solid var(--sh-primary); */
  font-weight: 700;
  border-radius: 0.5rem;
  background: var(--sh-primary-light);
  padding: 1px 12px;
  color: #fff;
  color: var(--sh-primary);
  margin-left: 10px;
}


.qc-check-modal .modal-dialog {
  margin-top: 5%;
}

.qc-check-modal .modal-header {
  font-weight: 500;
  padding: 15px;
  background: var(--sh-primary-light);
  border-radius: 0.5rem 0.5rem 0 0;
  color: var(--bs-heading-color);
  border-bottom: none;
}

.qc-check-modal .modal-header .modal-title {
  font-size: 1.25rem;
}

.qc-check-modal .modal-footer {
  border-top: none;
  justify-content: end;
}

.qc-check-modal .modal-body {
  margin-block: 15px 3px;
  padding: 15px;
}

.qc-check-modal .modal-dialog {
  max-width: 668px;
}

.store-name-info {
  left: 60%;
  width: fit-content;
  white-space: nowrap;
  height: 33px;
  min-height: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
}

.missing-info-text {
  color: #FC3B3B;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

.missing-info-tooltip {
  width: fit-content;
  white-space: nowrap;
  padding-block: 6px;
  min-height: unset;
  left: calc(100% + 1px);
  font-weight: 700;
}

.cell-inside-box .details-on-hover.missing-address .missing-details {
  color: #F31429;
  text-transform: capitalize;
  font-weight: 400;
}

.missing-address svg>g>path {
  fill: #F31429;
}

.search-container .popular-search {
  font-size: 10px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.search-container .popular-search span {
  cursor: pointer;
  white-space: nowrap;
  padding-inline: 10px;
  color: var(--sh-primary);
  border-radius: 0.5rem;
  border: 1px solid var(--sh-primary-light);
  padding-block: 2px;
}

.search-container .popular-search span.active {
  background: var(--sh-primary-light);
  border: none;
}

.search-container .popular-search .popular-search-tooltip {
  width: fit-content;
  white-space: nowrap;
  min-height: unset;
  top: 107%;
  left: 0%;
  bottom: unset;
  font-size: 12px;
  padding: 2px 10px;
  /* overflow: visible; */
}

/* 
.search-container .popular-search .popular-search-tooltip::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: rotate(180deg);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
} */

.order-related-dates {
  left: 100%;
}

.order-related-dates>span {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: fit-content;
}

.order-related-dates>span>span {
  display: inline-flex;
  white-space: nowrap;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
  align-items: center;
}

.confirmation-modal.add-qc-modal .modal-dialog {
  margin-top: 7%;
  max-width: 500px;
  min-width: 320px;
  width: calc(100% - 400px);
}

.confirmation-modal.add-qc-modal .modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.confirmation-modal.add-qc-modal .modal-header p,
.confirmation-modal.add-qc-modal .modal-header h2 {
  margin-bottom: 0;
}

.confirmation-modal.add-qc-modal form {
  display: flex;
  flex-direction: column;
  padding-inline: 15px;
  gap: 15px;
}

.confirmation-modal.add-qc-modal form label {
  display: flex;
  align-items: center;
}

.confirmation-modal.add-qc-modal form label * {
  /* flex: 1 1; */
  text-align: left;
}

.confirmation-modal.add-qc-modal form label span {
  flex-basis: 35%;
}

.confirmation-modal.add-qc-modal form label textarea,
.confirmation-modal.add-qc-modal form label input {
  flex-basis: 65%;
}

@media screen and (max-width:1366px) {
  .cell-inside-box p {
    font-size: 12px;
  }

  .orders-section-tabs .table-container {
    height: calc(100vh - 293px);
  }
}