.nodata-box {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.nodata-box img {
    width: auto;
    height: auto;
    max-height: 17rem;
}