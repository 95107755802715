.carousel-container {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    margin-bottom: 40px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.carousel-items {
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel-item {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9ecef;
    font-size: 24px;
    font-weight: bold;
    color: #495057;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    overflow: hidden;
    width: 80%;
    /* Adjust width as needed */
    height: 80%;
    /* Adjust height as needed */
    top: 10%;
}

.carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-nav {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}

.carousel-nav button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.carousel-nav button:hover {
    background-color: #0056b3;
}

.carousel-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    z-index: 10;
}

.carousel-dots span {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #ced4da;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.carousel-dots span.active {
    background-color: #007bff;
}