.shipment-progress {
    font-family: Arial, sans-serif;
    width: 50%;
}

.shipment-progress h3 {
    margin-bottom: 20px;
    font-size: 1rem;
    margin-top: 0;
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
}

.shipment-progress .progress-container {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: calc(100% - 45px);
    overflow: auto;
}

.shipment-progress .progress-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    position: relative;
    gap: 20px;
}

.shipment-progress .progress-circle {
    width: 14px;
    height: 14px;
    background-color: green;
    border-radius: 50%;
    position: relative;
    top: 5px;
}

.shipment-progress .progress-line {
    width: 2px;
    height: 100%;
    background-color: green;
    position: absolute;
    left: 6px;
    top: 18px;
}

.shipment-progress .progress-content {
    text-align: start;
    flex-basis: 98%;
}

.shipment-progress .date-time {
    font-size: 0.8rem;
    color: #555;
    margin-bottom: 5px;
}

.shipment-progress .status {
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0;
}

.shipment-progress .details {
    font-size: 0.7rem;
    color: #888;
    margin-top: 5px;
}

.shipment-progress .progress-item:last-child .progress-line {
    display: none;
}