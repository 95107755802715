.migration-news {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background: #111111b8;
    display: flex;
    align-items: center;
    justify-content: center;
}


.migration-news .container {
    max-width: 1200px;
    margin: 0;
    width: 100%;
    height: auto;
    padding: 40px 20px 0 20px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
}

.migration-news .welcome-banner {
    text-align: center;
    margin-bottom: 30px;
}

.migration-news .section {
    margin-bottom: 40px;
}

.migration-news h1 {
    margin-bottom: 0;
}

.migration-news .section h2 {
    color: #333;
}

.migration-news .button {
    display: block;
    width: 100%;
    max-width: 300px;
    margin-top: 20px;
    margin-inline: auto;
    padding: 15px;
    text-align: center;
    background-color: #599bd8;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
}