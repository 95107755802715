.reports-mis .filter-container {
    z-index: 2;
    position: relative;
}

.reports-mis .table-container {
    z-index: 1;
    position: relative;
    height: calc(100vh - 305px);
    overflow-y: auto;
}

.downloads-mis .table-container {
    height: calc(100vh - 281px);
}

.reports-mis .filter-container {
    align-items: end;
    gap: 3rem;
}

.reports-mis .search-container,
.downloads-mis .search-container {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    width: 100%;
}

.downloads-mis .search-container label button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none;
    cursor: pointer;
    background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
    color: #fff;
    width: 36px;
    height: 36px;
    border-radius: 0 0.5rem 0.5rem 0;
}

.reports-mis .search-container label input {
    border-color: var(--sh-primary-middle);
    border-radius: 0.5rem;
    padding-left: 32px;
}

.downloads-mis .search-container label input {
    padding-left: 10px;
}

.reports-mis .css-13cymwt-control,
.reports-mis .css-16xfy0z-control,
.reports-mis .css-t3ipsp-control {
    height: 36px;
    min-height: unset;
}

.reports-mis .css-13cymwt-control,
.reports-mis .css-t3ipsp-control,
.reports-mis .css-t3ipsp-control:hover {
    border-color: var(--sh-primary-middle);
    border-radius: 0.5rem;
    border-width: 1px;
    box-shadow: none;
}

.mis-page-container .bulk-action-container {
    z-index: 1;
}

/* .reports-mis .search-container>label,
.reports-mis .search-container>div {
    flex: 1 1;
} */

.reports-mis .search-container .option-labels {
    display: flex;
    gap: 20px;
}

.reports-mis .search-container .option-labels label {
    white-space: nowrap;
    flex: 1 1;
}

.reports-mis .search-container .date-picker-container {
    max-width: 250px;
}

.reports-mis .css-13cymwt-control {
    min-width: 150px;
}