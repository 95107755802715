@media screen and (max-width:1600px) {
    .chat-container {
        height: calc(100vh - 385px);
    }

    .edit-order-body span.unit.pd-lbh {
        right: 12px;
    }

    .form-box-h {
        height: calc(100vh - 275px);
    }

    .bulk-import-input {
        min-height: 200px;
    }

    .support-page .table-container {
        height: calc(100vh - 270px);
    }

    .billing-page-container .table-container {
        height: calc(100vh - 269px);
    }

    .downloads-mis .table-container {
        height: calc(100vh - 269px);
    }

    .reports-mis .table-container {
        height: calc(100vh - 290px);
    }

    .additional-features {
        min-height: 20rem;
    }

    .settings-page-container {
        grid-template-columns: repeat(4, 1fr);
    }

    .confirmation-modal.add-user-pop .modal-dialog {
        margin-top: 1%;
    }
}

@media screen and (max-width:1366px) {
    .manage-warehouse .box-grid {
        grid-template-columns: repeat(3, minmax(150px, 1fr));
    }

    .add-warehouse-section .inputs-container {
        width: 90%
    }

    .action-options .action-list.processing {
        right: 59px;
        position: fixed;
        top: 48% !important;
        bottom: unset !important;
    }

}

@media screen and (max-width:1305px) {
    .create-rules-section .created-rules {
        width: fit-content;
        min-width: 100%;
    }

    .table-row th {
        white-space: nowrap;
    }
}

@media screen and (max-width:1266px) {
    .page-settings-container .tracking-form-container {
        width: 100%;
    }

    .page-settings-container .page-settings-main {
        flex-direction: column;
    }

    .page-settings-container .save-button-container {
        display: flex;
        justify-content: end;
    }

    .page-settings-container .live-preview {
        overflow-x: auto;
        width: 100%;
    }

    .page-settings-container .tracking-page-preview {
        min-width: 660px;
    }
}

@media screen and (max-width:1240px) {
    .ship-container-row {
        flex-wrap: wrap;
    }

    .new-scheduler-slider {
        width: 92%;
    }

    .settings-page-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width:1187px) {

    .orders-section-tabs .table-container::-webkit-scrollbar {
        height: 2px;
    }

    .orders-section-tabs .table-container {
        height: calc(100vh - 330px);
    }

    .cell-inside-box p {
        white-space: nowrap;
    }

    #navTabs .alignContent {
        gap: 8px;
    }

}

@media screen and (max-width:1142px) {

    .orders-section-tabs .table-container.m-table-height {
        height: calc(100vh - 256px);
    }
}

@media screen and (max-width:1140px) {

    .status-container-item .status-item {
        flex-wrap: wrap;
        gap: 10px;
    }

    .status-container-item .status-item .status-counter {
        margin-inline: 20px;
    }

    .support-right-section .search-container {
        width: auto;
    }
}

@media screen and (max-width:1095px) {}

@media screen and (max-width:993px) {}


@media screen and (max-width:991px) {

    /* #shipEaseNavTabs {
        display: none;
    } */

    #shipEaseNavbarNav {
        width: calc(100vw - 20px) !important;
    }

    .SideNav {
        left: -50px;
    }

    .SideNav.expanded {
        z-index: 6;
        left: 0;
    }

    .sidenav-toggle-icon {
        display: flex;
        gap: 3px;
        align-items: center;
    }

    .sidenav-toggle-icon img {
        width: 30px;
        height: 30px;
    }

    .sidenav-toggle-icon button {
        border: none;
        background: transparent;
        padding: 0;
        margin: 0;
    }

    .rightContainer {
        margin-inline: 10px;
        width: calc(100vw - 20px) !important;
    }

    .cardsSpace {
        margin-bottom: 20px;
        padding-inline: 0;
    }

    .cardsSpace:first-of-type {
        order: 2;
        padding-right: 10px;
    }

    .cardsSpace:last-of-type {
        order: 3;
        padding-left: 10px;
    }

    #shipEaseNavTabs .nav-item {
        border: 1px solid var(--sh-primary-middle);
        padding: 0px 10px;
        border-radius: 0.5rem;
        width: 164px;
    }

    #shipEaseNavTabs .wr-nav-item {
        width: 185px;
    }

    .support-page #shipEaseNavTabs .nav-item {
        padding-block: 0px;
        width: 113px;
    }

    #shipEaseNavTabs .nav-item .dropdown-toggle {
        position: relative;
        padding-block: 3px;
    }

    #shipEaseNavTabs .nav-item .dropdown-toggle::after {
        position: absolute;
        right: 0;
        top: 10px;
    }

    #shipEaseNavTabs .dropdown-menu {
        top: 36px;
        left: 7px;
    }

    .m-inline-1 {
        margin-inline: 1px;
    }

    .last-thirty-table {
        overflow-x: auto;
    }

    .last-thirty-table::-webkit-scrollbar {
        height: 3px;
    }

    .search-container,
    .search-container label {
        width: fit-content;
    }

    .pagination-container {
        flex-direction: column;
    }

    .pagination-container .pagination {
        gap: 5px;
    }

    .pagination-container .pagination p {
        white-space: nowrap;
    }

    .pagination-container .items-per-page-dropdown select,
    .pagination-container .go-to-page input {
        width: 62px;
    }

    .orders-section-tabs .table-container {
        height: calc(100vh - 333px);
    }

    .ship-container-row .img-container {
        box-shadow: none;
    }

    .bulk-action-container {
        box-shadow: 0 0 18px 10px rgba(0, 0, 0, 0.1) !important;
        width: 100%;
    }

    .ba-inner-container {
        flex-direction: column;
    }

    .ba-actions {
        grid-template-columns: repeat(3, 1fr);
        align-items: start;
        gap: unset;
        padding-inline: 0px;
        margin-block: 10px;
        row-gap: 10px;
        justify-content: space-around;
    }

    .ba-actions.All {
        grid-template-columns: repeat(4, 1fr);
    }

    .ba-actions.Returns {
        grid-template-columns: repeat(2, 1fr);
    }

    .ba-rows-selected {
        padding-block: 3px;
        width: 100%;
        border-radius: 0.5rem 0.5rem 0 0;
    }

    .ba-actions li::after {
        display: none;
    }

    .ba-popup-container .ba-pop-show.open {
        max-width: 320px;
    }

    .ba-pop-show.weight-update.open {
        width: 80%;
        max-width: unset;
    }

    .ba-actions li {
        flex: 1 1;
    }

    .stepper-form-container .stepper-line {
        width: 98%;
    }

    .orders-section-tabs .table-container.m-table-height {
        height: calc(100vh - 239px);
    }

    .support-page .table-container {
        height: calc(100vh - 300px);
    }

    .billing-page-container .table-container {
        height: calc(100vh - 299px);
    }

    .selected-option {
        padding-block: 3px;
    }

    .rate-calc-page section.rate-des label {
        max-width: unset;
    }

    .ServiceabilityPage .pair-hr {
        margin-block: 15px 6px;
        width: 65%;
    }

    .search-container label input {
        height: 29px;
        padding-block: 0px;
    }

    .search-container.ot-filters label button {
        height: 29px;
    }

    .search-container.ot-filters label .css-15lsz6c-indicatorContainer,
    .search-container.ot-filters label .css-1xc3v61-indicatorContainer {
        padding-block: 0px;
    }

    .search-container.ot-filters label .css-1fdsijx-ValueContainer,
    .search-container.ot-filters label .css-hlgwow {
        height: 29px;
        padding-block: 0px;
    }

    .search-container.ot-filters label .css-qbdosj-Input,
    .search-container.ot-filters label .css-19bb58m {
        margin-block: 0px;
        padding-block: 0px;
    }

    .support-right-section .search-container,
    .support-right-section .search-container label,
    .support-right-section .search-container input,
    .support-right-section .search-container button {
        height: 29px;
    }

    .courier-dashboard .accordion-row hr {
        margin-block: 3px 14px !important;
    }

    .courier-dashboard .accordion-header {
        display: grid;
        grid-template-columns: auto auto;
        padding-bottom: 20px;
    }

    .courier-dashboard .accordion-header span {
        bottom: 0;
        position: absolute;
        right: 0;
    }

    .downloads-mis .table-container {
        height: calc(100vh - 298px);
    }

    .reports-mis .search-container>label,
    .reports-mis .search-container .option-labels>label,
    .reports-mis .search-container>div {
        flex: unset;
        width: 100%;
    }

    .reports-mis .search-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 10px;
        column-gap: 10px;
    }

    .reports-mis .search-container> :nth-child(1) {
        grid-column: 1 / span 3;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .reports-mis .search-container .date-picker-container {
        max-width: unset;
    }

    .reports-mis .table-container {
        height: calc(100vh - 389px);
    }

    .downloads-mis .search-container label button {
        height: 29px;
    }

    .downloads-mis .search-container .main-button-outline,
    .manage-warehouse .search-container .main-button-outline {
        padding-block: 3px;
    }

    .manage-warehouse .search-container label input {
        max-width: 207px;
    }

    .manage-warehouse .search-container label button {
        height: 29px;
    }

    .manage-warehouse .filter-container .nav-actions-container:hover .nav-actions-list {
        width: 182px;
        height: 160px;
    }

    .manage-warehouse .box-grid {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }

    .reports-mis .search-container .type-label {
        gap: 0;
        flex-direction: column;
        align-items: start;
    }

    .reports-mis .search-container .type-label .css-b62m3t-container {
        min-width: 100%;
    }

    .settings-page-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .menu-container {
        height: calc(100vh - 106px);
    }

    #sidenav button.nav-link {
        cursor: pointer;
        padding-inline: 6px;
        white-space: nowrap;
        padding-block: 7px;
        border-radius: 0.5rem;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
}



@media screen and (max-width:767px) {

    .signup-section .signup,
    .login-section .login {
        overflow-y: auto;
    }

    .signup-section .right-side,
    .login-section .right-side {
        background: url('./assets/image/bg_login.png');
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-block: 20px 200px;
    }

    .signup-section .left-side,
    .login-section .left-side {
        display: none;
    }

    .signup-section .signup .content,
    .login-section .signin .content {
        margin-inline: 0;
        max-width: 95%;
        min-width: 95%;
    }

    .signup-section .signup .signup-logo-container::before,
    .signup-section .signup .signup-logo-container img,
    .login-section .signin .login-logo-container::before,
    .login-section .signin .login-logo-container img {
        display: none;
    }

    .signup-section .signup .content img,
    .login-section .signin .content img {
        display: block;
    }

    .signup-section .signup .content {
        padding-inline: 20px;
        margin-bottom: 20px;
    }

    .signup-section .signup .signup-with-social {
        flex-direction: column;
    }

    .ba-pop-show.weight-update.open {
        width: 95%;
    }

    .weight-update .pop-content .lbh-labels .unit {
        width: 29px;
    }

    .volumetric-weight {
        font-size: 1rem;
    }

    .edit-order-section {
        width: 92%;
    }

    .stepper-form-container .inputs-container {
        width: 100%;
        max-width: 263px;
    }

    .stepper-form-container .inputs-container .row:nth-of-type(5) {
        flex-direction: column;
    }

    .stepper-form-container .steps-header {
        padding-top: 0;
    }

    .steps-header {
        height: 50px;
        position: relative;
        /* overflow: hidden; */
    }

    .steps-header>div:first-of-type {
        width: 288px;
        overflow: hidden;
        position: relative;
        height: 40px;
        margin-inline: auto;
    }

    .stepper-form-container .stepper-line {
        white-space: nowrap;
        transition: 1s;
        position: absolute;
        top: 12px;
    }

    .stepper-form-container .stepper-line .step-marker {
        margin-right: 21px;
        transition: 1s;
    }

    .stepper-form-container .stepper-line .step-marker {
        width: 100%;
    }

    .stepper-form-container .stepper-line .step-marker.completed {
        width: 0;
        overflow: hidden;
        margin-right: 0;
        transition: 1s;
    }

    .not-active-message {
        width: 90%;
        left: 5%;
    }

    .bulk-import-input {
        width: 95%;
    }

    .bo-or-text::after,
    .bo-or-text::before {
        width: 104px;
    }

    .support-page .help-button {
        top: -3px;
    }

    .zone-mapping-container {
        width: 90%;
        left: 5%;
    }

    .new-scheduler-slider .grid-container {
        display: flex;
        row-gap: 10px;
        flex-direction: column;
    }

    .new-scheduler-slider .grid-container .grid-item.component-2 {
        order: 2;
    }

    .courier-preference .courier-preference-list {
        flex-wrap: wrap;
    }

    .default-sorting-section {
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 15px;
    }

    .default-sorting-section label {
        flex-direction: column;
    }

    .cardsSpace:last-of-type,
    .cardsSpace:first-of-type {
        padding-inline: 0px;
    }

    .cardsSpace {
        min-width: 100%;
    }

    .courier-dashboard .counters-container {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        gap: 10px;
    }

    .int-body {
        flex-direction: column;
    }

    .int-body>section {
        width: 100%;
    }

    .warehouse-edit.open {
        width: 92%;
    }

    .search-container .popular-search {
        flex-wrap: wrap;
    }
}

@media screen and (max-width:633px) {
    .edit-order-header h2 {
        font-size: 20px;
    }

    .settings-page-container {
        grid-template-columns: repeat(1, 1fr);
    }
}


@media screen and (max-width:628px) {
    .support-page .table-container {
        height: calc(100vh - 317px);
    }

    .awb-tracking-slider {
        width: 92%;
    }

    .morefilters-panel {
        width: 92%;
    }

    .filter-row {
        flex-direction: column;
    }

    .morefilters-panel label.filter-date-by select {
        width: 100%;
    }

    .manage-warehouse .box-grid {
        grid-template-columns: repeat(1, minmax(150px, 1fr));
    }

    .logo-file-upload .upload-logo-input span {
        font-size: 1rem;
    }

    .logo-file-upload .upload-logo-input span .upload-icon {
        font-size: 1.1rem;
    }
}

@media screen and (max-width:567px) {
    .edit-order-body .details-side .details-component .warehouse-options .sm-warehouse-item {
        width: 100%;
    }


    .courier-dashboard .counters-container {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
    }
}


@media screen and (max-width:543px) {

    .single-ship-container {
        top: 4%;
        height: 92vh;
        width: 90vw;
    }

    .single-ship-container.open {
        right: 4%;
    }

    .ship-container-row .ship-ratings {
        flex-direction: column-reverse;
        width: 100%;
        flex-wrap: wrap;
    }

    .rating label {
        font-size: 20px;
    }

    .ship-container-row {
        gap: 10px;
        padding-top: 21px;
        /* justify-content: end; */
        margin-inline: 12px;
        margin-top: 0px;
    }

    .ship-container-row p {
        font-size: 12px;
    }

    .ss-shipment-charges p {
        text-align: end;
    }

    .weight-update .pop-content .lbh-labels {
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 0;
    }

    .weight-update .pop-content label {
        max-width: 130px;
    }

    .ba-actions {
        display: grid;
    }

    .edit-order-body .navigation-side ul li {
        font-size: 12px;
        padding-inline: 10px;
    }



    .edit-order-section.open-edit #sidepanel-closer {
        bottom: 36%;
        top: unset;
    }

    .mps-inputs label:nth-of-type(2) {
        width: auto;
    }

    .mps-inputs label:nth-of-type(2) input {
        min-width: unset;
        width: 100%;
    }

    .customer-details-form .details-form-row {
        padding-inline: 0px;
    }

    .reports-mis .search-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 4px;
        justify-content: flex-end;
    }

    .reports-mis .table-container {
        height: calc(100vh - 481px);
    }
}

@media screen and (max-width:511px) {
    .ServiceabilityPage .fm-lm-hr {
        width: 100%;
        height: 1px;
        min-height: unset;
        margin-block: 40px;
        background: linear-gradient(272deg, rgba(255, 255, 255, 1) 0%, rgba(25, 117, 201, 1) 25%, rgba(25, 117, 201, 1) 75%, rgba(255, 255, 255, 1) 100%);
    }

    .seller-profile-section {
        width: 23em;
    }
}

@media screen and (max-width:472px) {
    .orders-section-tabs .table-container {
        height: calc(100vh - 348px);
    }

    .pagination-container .go-to-page input {
        width: 30px;
        padding-left: 5px;
    }

    .pagination-container .go-to-page button {
        width: 30px;
    }

    .search-container label input {
        max-width: 320px;
    }

    .inputs-container label .select-field {
        min-width: unset;
        width: 100%;
    }

    .edit-order-body .details-side .details-component .inputs-container .row:nth-of-type(5)>label {
        width: 100%;
    }

    .edit-order-body .details-side .details-component .inputs-container .row:nth-of-type(5) {
        flex-direction: column;
    }

    .wallet-container.show {
        right: unset;
        left: unset;
    }

    .wallet-box {
        width: 95%;
    }

    .support-right-section .search-container,
    .support-right-section .search-container label,
    .support-right-section .search-container input {
        width: 171px;
    }

    .support-right-section {
        gap: 5px;
    }


    .ticket-slider {
        width: 90%;
    }

    .ticket-view-field {
        flex-direction: column;
    }

    .chat-container {
        height: calc(100vh - 411px);
    }

    .tracking-header {
        flex-direction: column;
        align-items: start;
        height: 100px;
        padding-block: 0;
        justify-content: center;
        gap: 5px;
    }

    .agreement-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .ndr-funnel .nav-item button {
        width: 81px;
    }

    .courier-dashboard .accordion-header {
        grid-template-columns: auto;
    }

    .card-grid-container {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .notification-panel {
        width: 90%;
    }

    .iconContainer {
        width: 27px;
    }
}

@media screen and (max-width:400px) {

    .int-header-left {
        white-space: nowrap;
    }

    .int-header-left h2 {
        font-size: 18px;
    }

    .int-header-right {
        white-space: nowrap;
    }

    .int-header-right:after {
        left: 30px;
    }
}

@media screen and (max-width:375px) {
    .orders-section-tabs .table-container {
        height: calc(100vh - 348px);
    }

    .search-container label input {
        max-width: 277px;
    }

    .ba-pop-show .pop-heading {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding-block: 10px;
    }

    .edit-order-body label,
    .edit-order-body .input-field {
        width: 181px;
        min-width: unset;
    }

    .mps-inputs label {
        width: 47px
    }
}