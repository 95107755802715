.navtabs {
    list-style: none;
}

.navtabs li {
    background-color: burlywood;
    padding: 20px;
    border: 1px solid;
    cursor: pointer;
}

.dashboard-table table td {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard-table .table-ui th,
.last-orders .table-ui th {
    background: transparent;
}

.dashboard-table .table>:not(:last-child)>:last-child>*,
.last-orders .table>:not(:last-child)>:last-child>* {
    border-bottom-color: inherit;
}

.dashboard-table .table-hover>tbody>tr:hover,
.last-orders .table-hover>tbody>tr:hover {
    --bs-table-accent-bg: transparent;
}

/* .top-selling-page .table tbody tr:nth-of-type(odd), */
.last-orders .table tbody tr:nth-of-type(odd) {
    --bs-table-accent-bg: #CEDBF2;
}

.dashboard-table table.custom-table tr td,
.tdashboard-table table.custom-table tr th {
    padding: 5px 10px;
}

.dashboard-table table.custom-table tr td {
    font-size: 1rem;
    text-transform: capitalize;
}

.dashboard-table table.custom-table tr th {
    font-size: 0.8rem;
    padding: 5px 10px;
}

.dashboard-table table.custom-table tbody tr:nth-of-type(odd) td {
    background-color: var(--sh-primary-light);
}

.dashboard-table table.custom-table tbody tr:nth-of-type(odd) td:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
}

.dashboard-table table.custom-table tbody tr:nth-of-type(odd) td:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
}


.state-wise-card .card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.state-wise-card .export-report {
    font-size: 12px;
    padding: 5px;
    background: #CEDBF2;
    border-radius: 4px;
    color: #111;
    margin-bottom: 15px;
}

.state-wise-card .card-count {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.state-wise-card h5 {
    font-weight: 600;
    margin-bottom: 0;
}

.state-wise-card h5 span {
    font-weight: 400;
}

.state-wise-card p {
    margin-bottom: 0;
}

.state-wise-card .list-ui {
    margin-top: 1rem;
}

.state-wise-card .list-ui li {
    margin-bottom: 0;
    padding-block: 2px;
    justify-content: flex-end;
}

.content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-container .gender-side {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.content-container .gender-side svg {
    height: 22px;
}

.content-container .gender-side svg path {
    fill: #6A49E5;
}

.content-container .gender-side .male svg path {
    fill: #DB445B;
}

.content-container .gender-icon {
    background-color: #E7E1FD;
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}

.content-container .gender-icon.male {
    background-color: #F8D8DE;
}

.best-sku-products table {
    margin-bottom: 0;
    width: 100%;
}

.best-sku-products table tr {
    border-bottom: 1px solid #F2F2F2;
    padding-block: 1px;
    display: flex;
    justify-content: space-between;
}

.best-sku-products table td {
    white-space: nowrap;
    font-size: 12px;
    padding-block: 7px;
}

.best-sku-products table tr:last-of-type {
    border-bottom: none;
}

.best-sku-products table tr:last-of-type td {
    padding-bottom: 0px;
}

.graph-image {
    max-width: -webkit-fill-available;
}

.table-hover>tbody>tr:hover>* {
    --bs-table-color-state: var(--bs-table-hover-color);
    --bs-table-bg-state: #CEDBF2;
}

.state-wise-card svg {
    position: unset !important;
}

.state-wise-card .HoverInfo {
    margin-top: -50px;
}

.datamaps-subunit.has-data {
    fill: #FF5733;
}

.overview-details-counters .tab-buttons {
    display: flex;
    width: fit-content;
    gap: 30px;
    border-bottom: 1px solid #ddd;
}

.overview-details-counters .counter-tab {
    cursor: pointer;
    padding: 7px 15px;
    background-color: transparent;
}

.overview-details-counters .counter-tab.active {
    cursor: pointer;
    background-color: #fff;
    color: var(--sh-primary);
    border-bottom: 1px solid var(--sh-primary);
}

.overview-details-counters .tab-content {
    display: flex;
    align-items: center;
    height: 60%;
    justify-content: space-between;
    gap: 0.8rem;
    margin-top: 25px;
}

.overview-details-counters .counter-sets {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background: #26a0fc;
    color: #fff;
    border-radius: 0.5rem;
    width: 100%;
    text-align: center;
}

.overview-details-counters .counter-sets hr {
    border: none;
    background: rgb(0, 0, 0);
    background: linear-gradient(to right, #ffffff08, #fff, #ffffff08);
    height: 2px;
    width: 100%;
    margin-block: 0 10px;
}


.top-rto-pincodes .pincode-search {
    width: auto;
}

.dashboard-table table.custom-table tbody tr.prepaid-row td,
.dashboard-table table.custom-table tbody tr.cod-row td {
    background-color: #fff;
}

.dashboard-table table.custom-table tbody tr.prepaid-row td {
    padding-block: 15px 6px;
}

.dashboard-table table.custom-table tbody tr.cod-row td {
    padding-block: 0px 15px;
}

.dashboard-table table.custom-table tbody tr.prepaid-row td:first-of-type,
.dashboard-table table.custom-table tbody tr.cod-row td:first-of-type {
    padding-left: 30px;
}

.dashboard-table table.custom-table tbody tr.prepaid-row td:last-of-type,
.dashboard-table table.custom-table tbody tr.cod-row td:last-of-type {
    padding-right: 30px;
}

.dashboard-table table.custom-table tbody tr.prepaid-row,
.dashboard-table table.custom-table tbody tr.cod-row {
    position: relative;
}

.dashboard-table table.custom-table tbody tr.prepaid-row::after {
    content: "";
    position: absolute;
    width: 90%;
    background-color: #ddd;
    height: 1px;
    top: 8px;
    left: 28px;
}

.dashboard-table table.custom-table tbody tr.cod-row::after {
    content: "";
    position: absolute;
    width: 90%;
    background-color: #ddd;
    height: 1px;
    bottom: 8px;
    left: 28px;
}

.courier-dashboard .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.courier-dashboard .counters-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 20px;
    padding-right: 33px;
}

.courier-dashboard .accordion-header>*:not(span) {
    flex: 1;
}


.subaccount-perf select {
    min-width: unset;
    height: auto;
    padding-block: 5px;
}

.radial-bar .apexcharts-legend-series {
    margin: 1px !important;
}

.w-create-order text {
    fill: #111;
    transform: translate(1px, 3px);
}

.w-create-order .apexcharts-datalabels rect {
    height: 21px;
    width: 76px;
}

.status-card-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-around;
}

.status-container-item {
    width: 100%;
    height: auto;
    /* padding: 15px; */
    display: flex;
    /* background-color: var(--sh-primary-light); */
    border-radius: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.status-container-item p {
    margin-bottom: 0;
}

.status-container-item .status-header {
    display: inline-block;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 0.5rem 0 0 0.5rem;
    gap: 15px;
    width: 50%;
    background: var(--sh-primary-light);
    color: var(--sh-primary);
    padding-block: 4px;
    /* padding-inline: 8px; */
}

.status-container-item .status-header .header-polygon {
    display: block;
    height: 21px;
    width: 21px;
    background-color: #fff;
    border: inherit;
    position: absolute;
    top: 4px;
    right: -11px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(45deg);
    /* border-radius: 0 0 0 0.5rem; */
}

.status-container-item .status-header p {
    margin: auto;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    padding: 0px;
}

.status-container-item .status-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
}

.status-container-item .status-item .status-counter {
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: center;
    /* height: 65%; */
    flex-direction: column-reverse;
    justify-content: flex-end;
}

.status-container-item .status-item .status-counter p {
    text-align: center;
}

.status-container-item .status-item .status-counter p:first-of-type {
    font-size: 0.9rem;
    /* display: inline-block; */
    /* width: 63%; */
    white-space: nowrap;
    color: gray;
}


.status-container-item .status-item .status-counter p:last-of-type {
    /* display: flex; */
    /* width: 100%; */
    /* align-items: center; */
    /* justify-content: space-around; */
    /* color: var(--sh-primary); */
    font-size: 1.3rem;
}

.last-thirty-table {
    max-height: 400px;
    overflow-x: hidden;
}

.last-thirty-table thead tr {
    position: sticky;
    top: 0;
    background: #fff;
}

/* .india-map-container {
    height: 100%;
    display: flex;
    align-items: center;
} */

.status-card-container .circle-blinker {
    position: relative;
    width: 15px;
    height: 15px;
    display: inline-flex;
    background: var(--sh-primary);
    border-radius: 50%;
    /* vertical-align: -3px; */
    align-items: center;
    justify-content: center;
}

.status-card-container .circle-blinker span {
    border-radius: 50%;
    width: 50%;
    height: 50%;
    background: #fff;
    /* margin-top: 0; */
    /* animation: circleblinker 2s infinite; */
}


/* @keyframes circleblinker {
    50% {
        opacity: 70%;
    }
} */

.dashboard-table .pa-tootltip {
    width: auto;
    min-width: unset;
    max-width: unset;
    max-height: unset;
    min-height: unset;
    white-space: nowrap;
    left: 36px;
    bottom: -2px;
}

.non-active-service {
    position: absolute;
    background: #00000059;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 152px);
    z-index: 3;
    border-radius: 0.5rem;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.non-active-service .nas-message {
    background: #C8C8C8;
    padding: 30px 48px;
    border-radius: 0.5rem;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 7%;
    position: fixed;
}

#ndr-chart .apexcharts-legend-text {
    text-transform: lowercase;
}

/* #ndr-chart>div,
#ndr-chart>div>div {
    min-height: 400px !important;
}

#ndr-chart>div>div>svg,
#ndr-chart>div>div>svg>foreignObject {
    height: 400px;
} */

.tickets-chart .apexcharts-datalabels.apexcharts-hidden-element-shown {
    opacity: 0;
}

@media screen and (max-width: 1800px) {
    .top-rto-pincodes .pincode-search {
        width: 100%;
    }

    .apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
        right: 150px !important;
    }

    .radial-bar .apexcharts-legend-series {
        margin: 0 !important;
    }

}

@media screen and (max-width: 1600px) {
    .top-selling-page table td {
        max-width: 178px;
    }
}