.sidepanel-header {
    width: 100%;
    background-color: #CEDBF2;
    padding: 15px 20px;
}

#sidepanel-closer {
    position: absolute;
    top: 45%;
    width: 40px;
    height: 40px;
    display: flex;
    left: -19px;
    background-color: #fff;
    border-radius: 120px;
    /* padding: 11px; */
    /* border: 1px solid; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sidepanel-body {
    margin-block: 20px;
    margin-inline: 20px;
    position: relative;
    height: calc(100vh - 140px);
}

/* .sidepanel-body form{
    display: flex;
    flex-direction: column;
    gap: 15px;
} */

.form-input-fields {
    max-height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
    padding-inline: 10px;
}

.filter-row {
    display: flex;
    gap: 15px;
    width: 100%;
}

.filter-row label {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.more-filters-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 36px;
    overflow-y: hidden;
}

.more-filters-footer label {
    display: flex;
    align-items: center;
    gap: 10px;
}

.more-filters-footer .filter-name-ip {
    width: 155px;
    min-width: unset;
    animation: inputAppear 1s ease;
}

@keyframes inputAppear {
    from {
        width: 0px;
    }

    to {
        width: 150px;
    }
}

.morefilters-panel .rmsc .dropdown-container {
    background-color: transparent;
    border: 1px solid var(--sh-primary-middle);
    border-radius: 0.5rem;
    padding-left: 10px;
}

.morefilters-panel .rmsc .dropdown-content {
    width: 90%;
}

.morefilters-panel .form-input-fields {
    overflow-x: clip;
}

.morefilters-panel .css-b62m3t-container:focus-visible {
    outline: none;
}

.morefilters-panel .css-13cymwt-control {
    border-color: var(--sh-primary-middle);
    border-radius: 0.5rem;
}

.morefilters-panel .sku-checkbox label {
    display: flex;
    gap: 7px;
    flex-direction: row;
}

.morefilters-panel .react-datepicker__header {
    background-color: var(--sh-primary-light);
}

.morefilters-panel .react-datepicker__year-read-view--down-arrow,
.morefilters-panel .react-datepicker__month-read-view--down-arrow,
.morefilters-panel .react-datepicker__month-year-read-view--down-arrow,
.morefilters-panel .react-datepicker__navigation-icon::before {
    border-color: #000000;
    border-width: 2px 2px 0 0;
    height: 6px;
    width: 6px;
}

.morefilters-panel .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.morefilters-panel .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.morefilters-panel .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.morefilters-panel .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.morefilters-panel .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.morefilters-panel .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #000;
}


.morefilters-panel .react-datepicker__year-read-view--down-arrow,
.morefilters-panel .react-datepicker__month-read-view--down-arrow {
    top: 5px;
}

.morefilters-panel .react-datepicker__navigation-icon::before {
    top: 12px;
}

.morefilters-panel .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.morefilters-panel .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: var(--sh-primary-light);
}

/* 

.morefilters-panel .react-datepicker__close-icon {
    margin-right: 21px;
} */

.morefilters-panel .react-datepicker__close-icon::after {
    background-color: transparent;
    content: '\00d7';
    font-size: 20px;
    font-weight: 600;
    color: var(--sh-primary);
    vertical-align: bottom;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    fill: #c5dcf1;
    color: #c5dcf1;
}

.morefilters-panel .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    display: none;
}

.morefilters-panel .react-datepicker__year-read-view,
.morefilters-panel .react-datepicker__month-read-view {
    visibility: visible !important;
}

.morefilters-panel .react-datepicker__year-dropdown,
.morefilters-panel .react-datepicker__month-dropdown,
.morefilters-panel .react-datepicker__month-year-dropdown {
    background-color: var(--sh-primary-light);
}

.morefilters-panel .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    border: 1px solid var(--sh-primary-middle);
    border-radius: 0.5rem;
    width: 100%;
}

.morefilters-panel .react-datepicker__header .react-datepicker__header__dropdown--scroll {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.morefilters-panel .react-datepicker__year-dropdown-container--select,
.morefilters-panel .react-datepicker__month-dropdown-container--select,
.morefilters-panel .react-datepicker__month-year-dropdown-container--select,
.morefilters-panel .react-datepicker__year-dropdown-container--scroll,
.morefilters-panel .react-datepicker__month-dropdown-container--scroll,
.morefilters-panel .react-datepicker__month-year-dropdown-container--scroll {
    margin-inline: 0 15px;
}

/* 
.morefilters-panel label.filter-date-by {
    width: auto;
}

.morefilters-panel label.filter-date-by select {
    width: 80px;
} */

/* 
.morefilters-panel ::-webkit-scrollbar {
    width: 2px;
}

.morefilters-panel ::-webkit-scrollbar-track {
    background: transparent;
}

.morefilters-panel ::-webkit-scrollbar-thumb {
    background: var(--sh-primary-light);
    border-radius: 6px;
} */