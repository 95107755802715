.single-ship-container {
    width: 80vw;
    height: 80vh;
    overflow-y: clip;
    background-color: var(--sh-primary-light);
    position: fixed;
    top: 10%;
    right: -100vw;
    z-index: 6;
    transition: 0.5s;
    border-radius: 0.5rem;
}

.single-ship-container.open {
    right: 8%;
}

.single-ship-container p {
    margin-bottom: 0px;
}

.single-ship-container .close-button {
    padding-block: 0;
}

.ss-container-main {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.ship-container-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block: 1.7rem 0.5rem;
    margin-inline: 2rem;
    padding: 0.2rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    position: relative;
}

.ship-container-row:last-of-type {
    margin-bottom: 30px;
}



.ship-container-row .img-container {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 100%;
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3);
    transition: all 0.6s ease;
}

.ship-container-row .img-container img {
    max-width: 100%;
    height: auto;
}

/* .performance-rating {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
} */

.recommended {
    position: absolute;
    overflow: hidden;
    width: 150px;
    height: 150px;
    top: -43px;
    left: -32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recommended::before {
    content: 'Recommended';
    position: absolute;
    top: 66px;
    left: 16px;
    width: 107px;
    height: 26px;
    background-image: linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%);
    transform: rotate(318deg) translateY(-20px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
    font-size: 10px;
    border-radius: 0.5rem 0.5rem 0 0;
}

.recommended::after {
    content: '';
    position: absolute;
    width: 9px;
    bottom: 40px;
    left: 26px;
    height: 9px;
    z-index: -1;
    box-shadow: 69px -63px #cc3f47;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
}

table.performance-rating tr td {
    font-size: 0.8rem;
    margin-block: 0px;
    padding-block: 0px;
    padding-right: 5px;
}

.performance-rating p {
    display: flex;
    align-items: center;
    gap: 5px;
}

.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
    /* Adjust the width as needed */
}

.chart-container p {
    white-space: nowrap;
}

.ss-shipment-charges p {
    font-size: 1.2rem;
}

.ss-shipment-charges p span {
    font-size: 0.8rem;
}


/* stars rating */
/* Star Rating Container */
/* Star Rating Container */
.rating {
    display: inline-block;
    font-size: 0;
    /* Remove the gap between inline-block elements */
}

/* Hide the radio inputs */
.rating input[type="radio"] {
    display: none;
}

/* Star label styling */
.rating label {
    display: inline-block;
    font-size: 24px;
    /* Adjust star size as needed */
    color: gold;
    /* Default star color */
    cursor: pointer;
}

/* Highlighted star styling */
.rating input[type="radio"]:checked~label {
    color: gold;
    /* Color of highlighted stars */
}

.quick-ship-exit {
    width: 24%;
    height: 300px;
    background: #fff;
    position: fixed;
    top: -500px;
    /* left: 38%; */
    margin-inline: auto;
    z-index: 8;
    transition: 0.5s;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-width: 300px;
}

.quick-ship-exit.open {
    top: 20%;
}

.quick-ship-exit .confirmation-header {
    padding: 20px 15px;
    background: var(--sh-primary-light);
}

.quick-ship-exit .confirmation-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 15px;
}