.login-section {
    /* background-image: url(../../../assets/image/loginBg.png);
    background-repeat: repeat-y;
    background-size: contain; */
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
}

@keyframes animate {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(100%);
    }
}

.login-section .signin {
    height: 100vh;
    position: relative;
}

/* .login-section .signin .login-lc-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
} */

.login-section .signin .login-logo-container {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: fit-content;
}

.login-section .signin .login-logo-container::before {
    /* content: "";
    display: flex;
    position: fixed;
    width: 22rem;
    height: 22rem;
    background: linear-gradient(135deg, var(--primary-10), var(--primary-0));
    border-radius: 77px;
    bottom: -11rem;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    z-index: -1; */
    content: "";
    display: flex;
    position: fixed;
    width: 22rem;
    height: 22rem;
    background: linear-gradient(135deg, var(--bs-white), var(--bs-white));
    border-radius: 77px;
    top: -225px;
    left: 69px;
    transform: translateX(-50%) rotate(306deg);
    z-index: 0;
}

.login-section .signin .login-logo-container img {
    height: 24px;
    z-index: 2;
    position: absolute;
    top: 2.5rem;
    left: 1.5rem;
}

.login-section .left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: url('../../../assets/image/bg_login.png');
    background-size: cover;
    background-position: center;
}

.login-section .left-side img {
    width: 87%;
    height: auto;
    /* margin-right: -70px; */
}


.login-section .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    background: url(../../../assets/image/leftSidePattern.svg);
    background-position: center;
    background-size: contain;
}


.login-section .signin .content {
    padding: 40px 48px;
    min-width: 35.6rem;
    min-height: 63vh;
    background: #fff;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 34px;
    border: 1px solid #d3d3d3;
    border-radius: 20px;
    margin-right: 80px;
    background-color: #fff;
}

.login-section .signin .content img {
    display: none;
}


.login-section .signin .content h2 {
    font-size: 2em;
    color: var(--sh-primary);
    text-transform: uppercase;
}

.login-section .signin .content .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.login-section .signin .content .form .inputBox {
    position: relative;
    width: 100%;
}

.login-section .signin .content .form .inputBox input {
    position: relative;
    width: 100%;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1em;
}

.login-section .signin .content .form .inputBox i {
    position: absolute;
    left: 0;
    padding: 6px 10px;
    font-style: normal;
    color: #aaa;
    transition: 0.5s;
    pointer-events: none;
}

.login-section .signin .content .form .inputBox input:focus~i,
.login-section .signin .content .form .inputBox input:valid~i {
    transform: translateY(-1rem);
    font-size: 0.8em;
    color: transparent;
    user-select: none;
}

.login-section .signin .content .form .links {
    position: relative;
    width: 100%;
    display: flex;
    font-size: 0.8rem;
}

.login-section .signin .content .form .links button {
    font-size: 0.8rem;
}

.login-section .signin .content .form .links a {
    /* color: var(--sh-primary); */
    text-decoration: none;
}

/* .login-section .signin .content .form .links a:nth-child(2) {
    color: var(--sh-primary);
    font-weight: 600;
} */

.login-section .signin .signup-text {
    text-align: center;
}


.login-section .signin .signup-text button {
    color: var(--sh-primary);
}

.login-section .signin .content .form .inputBox input[type="submit"] {
    padding: 10px;
    background: var(--sh-primary);
    color: #000;
    font-weight: 600;
    font-size: 1.35em;
    letter-spacing: 0.05em;
    cursor: pointer;
}

.login-section input[type="submit"]:active {
    opacity: 0.6;
}

.other-signin-options {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.other-signin-options .login-options-buttons {
    display: flex;
    gap: 10px;
}


.other-signin-options button {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    min-width: 56px;
}

.other-signin-options button span {
    font-size: 0.8rem;
}

.eye-icons {
    position: absolute;
    right: 9px;
    top: 4px;
    font-size: 19px;
    cursor: pointer;
}