#navbarNav {
  display: flex;
  align-items: center;
  /* overflow: hidden;  */
  /* Prevent content from going outside the navbar */
}

#shipEaseNavbarNav .nav .nav-link {
  width: 100%;
  font-weight: 400;
  cursor: pointer;
}

/* #shipEaseNavbarNav .nav-link:active,
.nav-link:focus,
.nav-link:hover {
  background-color: #f3f3f3;
  background-color: #dbdbdb;
  border-radius: 4px;
  color: #0F3C5B;
} */

.iconContainer {
  width: 38px;
  height: 38px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transform-origin: top center;
}

.iconContainer.highlight {
  animation: bottomShake 0.5s infinite;
}

@keyframes bottomShake {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0);
  }
}

.navItemsContainer {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  font-weight: 400;
}

.buisnessItem {
  color: #fda005;
}

.buisnessItem span {
  background-color: #ffe2b0;
}

.rateCalculatorColor {
  color: #845adf;
}

.rateCalculatorColor span {
  background-color: #dcccff;
}

.referColor {
  color: #0b3b5b;
}

.referColor span {
  background-color: #dadde8;
}

.notificationIcon {
  color: #FFC107;
  font-size: 30px;
  /* background-color: #dff0fa; */
}

.settingIcon {
  color: #ff5b5b;
  background-color: #ffe6e6;
}

.walletContainer .walletIcon {
  /* background-color: var(--sh-primary-light); */
  border: 1px solid var(--sh-primary);
  width: auto;
  display: flex;
  justify-content: space-between;
  color: var(--sh-primary);
  /* gap: 20px; */
  border-radius: 0.5rem;
  font-size: 30px;
  height: 36px;
  align-items: center;
  min-width: 150px;
}

.walletContainer .walletIcon.empty {
  border-color: #e60000;
}

/* .walletContainer .walletIcon svg path {
  fill: var(--sh-primary);
} */

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.walletContainer {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  position: relative;
}

.walletContainer .walletIcon .low-balance {
  position: absolute;
  top: 37px;
  right: 0;
  height: 0;
  /* height: 25px; */
  overflow: hidden;
  font-size: 0.8rem;
  border-radius: 0.5rem;
  padding-inline: 10px;
  display: flex;
  align-items: center;
  background: #ffc107;
  color: #121212;
  font-weight: 700;
  transition: 0.2s;
  z-index: 1;
}

.walletContainer:hover .walletIcon .low-balance {
  height: 25px;
}

.alignContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bell,
.cart {
  position: relative;
  cursor: pointer;
}

.bellColor {
  position: absolute;
  font-size: 12px;
  width: 20px;
  color: #ffffff;
  border-radius: 50%;
  top: 6px;
  right: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F44336;
}

.settingColor {
  background: #ff5b5b;
}

.user-photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.walletBalance {
  font-size: 18px;
  font-weight: 700;
}

.walletContainer .walletIcon.empty .walletBalance {
  color: #e60000;
}

.walletDays {
  font-size: 12px;
  font-weight: 400;
  color: #7e0300;
}

.navbar-expand .navbar-nav .dropdown-menu {
  left: -6.3rem;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  right: 0;
  left: unset;
}

.user-name {
  padding-inline: 10px;
  max-width: 112px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.dropdown-divider {
  opacity: 0.2;
}

#basic-nav-dropdown:hover,
#basic-nav-dropdown:focus {
  background-color: transparent;
}

#shipEaseNavbarNav {
  width: calc(100vw - 92px) !important;
}

.quick-actions-container {
  position: relative;
}

.quick-actions-hover {
  position: absolute;
  background-color: #eaf2fa;
  box-shadow: 8px 8px 7px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0.5rem;
  width: 0px;
  height: 0px;
  /* width: 252px; */
  /* height: 320px; */
  top: 38px;
  z-index: 3;
  transition: 0.5s;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quick-actions-hover.right-header {
  right: 0;
}


.quick-actions-hover .qa-hovered-content {
  display: flex;
  gap: 12px;
  margin-inline: 1rem;
  flex-direction: column;
  width: 100%;
}

.quick-actions-hover .qa-hovered-content p,
.quick-actions-hover .qa-hovered-content a {
  margin-bottom: 0;
  padding: 8px;
  border-radius: 0.5rem;
  background-color: #ffffff;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding-left: 20px;
  justify-content: flex-start;
  cursor: pointer;
  border: 1px solid transparent;
}

.quick-actions-hover .qa-hovered-content p:hover {
  background-color: var(--sh-primary-light);
  border-color: var(--sh-primary);
}

.quick-actions-container:hover .quick-actions-hover {
  width: 252px;
  height: 320px;
  z-index: 4;
}

.quick-action-text {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  border: 1px solid var(--sh-primary-light);
  padding-inline: 10px;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  height: 36px;
}

/* .quick-action-text::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
} */

.header-search-input {
  position: relative;
}

.header-search-input input {
  padding-left: 10px;
  border-color: var(--sh-primary-light);
  padding-right: 40px;
  min-width: 18rem;
}

.header-search-input input::placeholder {
  font-size: 0.9rem;
}

.header-search-input button {
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
  cursor: pointer;
  background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
  color: #fff;
  width: 36px;
  height: 36px;
  border-radius: 0 0.5rem 0.5rem 0;
}

.quick-actions-container:hover .quick-actions-hover.hl {
  height: 136px;
}

.user-image-container a {
  display: flex;
  align-items: center;
}

.user-image-container a span.user-image-icon {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
  color: inherit;
}

/* Media query for tablets */
@media only screen and (min-width: 1601px) {
  #shipEaseNavbarNav {
    width: calc(100vw - 92px) !important;
  }
}

@media only screen and (max-width: 768px) {

  /* Adjust styles for tablet screens here */
  /* For example, you might want to change the layout, font sizes, etc. */
  .navItemsContainer {
    flex-direction: column;
    gap: 10px;
  }

  .icons {
    justify-content: space-around;
  }

  .walletContainer {
    justify-content: center;
  }
}