/* .integration-container {
    padding: 15px;
} */


/* CardGrid.css */

.card-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 30px;
}

.card-grid-container .card {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

/* .card:hover {
    transform: scale(1.05);
} */

.card-grid-container .card:hover .card-img-container {
    flex-basis: 50%;
}

.card-grid-container .card .card-img-container img {
    transition: 1s;
}

.card-grid-container .card:hover .card-img-container img {
    transform: rotate(360deg);
}

.card-img-container {
    flex-basis: 10%;
    position: relative;
    height: 100%;
    transition: flex-basis 0.5s;
}

.card-img-container.Shopify {
    /* border-right: 1px solid #96BF48; */
    background-color: rgb(150 191 72 / 25%);
}

.card-img-container.WooCommerce {
    background-color: #E4DBEF;
    border-right: 1px solid #E4DBEF;
}

.card-img-container.Magento {
    /* border-right: 1px solid #f37b20; */
    background-color: #fcb283;
}

.card-img-container.StoreHippo {
    /* border-right: 1px solid #7fb53c; */
    background-color: #cae1af;
}

.card-img-container.Amazon {
    /* border-right: 1px solid #F8991D; */
    background-color: #FDDEB5;
}

.card-img-container.Flipkart {
    /* border-right: 1px solid #F8991D; */
    background-color: #f6dd91;
}


.card-img-container.Opencart {
    background-color: #b0e9fa;
}

.card-img-container.Manual {
    background-color: var(--sh-primary-light);
}

.card-img-container.EasyShip {
    background-color: #bce5ea;
    /* background-color: #8CD3DB; */
}

.card-img-container.Unicommerce {
    background-color: #98bcf5;
    /* background-color: #8CD3DB; */
}

.card-img-container.EasyEcom {
    background-color: #8fc9c5;
    /* background-color: #8CD3DB; */
}

.card-img-container.Vin.eRetail {
    background-color: #f58287;
    /* background-color: #8CD3DB; */
}

.card-img-container.OMS.Guru {
    background-color: #62a3d1;
    /* background-color: #8CD3DB; */
}

.card-img-container.ClickPost {
    background-color: #94c7ff;
    /* background-color: #8CD3DB; */
}

.card-img-container.WhatsApp {
    background-color: #cdf5dc;
    /* background-color: #8CD3DB; */
}

.card-img-container.IVR {
    background-color: #e0ecf8;
    /* background-color: #8CD3DB; */
}

.card-img-container.Pragma {
    background-color: #acc8f9;
    /* background-color: #8CD3DB; */
}

.card-img-container img {
    position: absolute;
    right: -18px;
    top: 21%;
}

.card-content {
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-title {
    font-size: 1.2rem;
    margin-bottom: 0px;
    white-space: nowrap;
}

.card-description {
    font-size: 1rem;
    color: #555;
}

/* integration form */

.int-header {
    background-color: #e8f1d7;
    width: 100%;
    display: flex;
    border-radius: 0.5rem;
    justify-content: space-between;
    overflow: hidden;
}

.woo .int-header {
    background-color: #e4dbef;
}

.magento .int-header {
    background-color: #fef0e6;
}

.amazon .int-header {
    background-color: #fddeb5;
}

.hippo .int-header {
    background-color: #c0d4d2;
}

.easyship .int-header {
    background-color: #bce5ea;
}

.clickpost .int-header {
    background-color: #94c7ff;
}

.easycom .int-header {
    background-color: #8fc9c5;
}

.omsguru .int-header {
    background-color: #b5d2e9;
}

.vineretail .int-header {
    background-color: #ead4dc;
}

.unicommerce .int-header {
    background-color: #c7daf7;
}

.whatsapp .int-header {
    background-color: #cdf5dc;
}

/* .int-form {
    width: 60%;
    margin-inline: auto;
    margin-block: 2rem;
} */

.int-form label {
    width: 100%;
}

.int-header-left {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.int-header-left img {
    width: 4rem;
}

.int-header-right {
    position: relative;
    padding: 15px;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 600;
}

.int-header-right p {
    z-index: 2;
}

.int-header-right:after {
    content: '';
    position: absolute;
    width: 150px;
    height: 250px;
    top: -83px;
    left: 1px;
    background-color: #95BF47;
    transform: rotate(-45deg);
    z-index: 1;
    border-radius: 0.5rem;
}

.woo .int-header-right:after {
    background-color: #7f54b3;
}

.magento .int-header-right:after {
    background-color: #f36306;
}

.amazon .int-header-right:after {
    background-color: #F8991D;
}

.hippo .int-header-right:after {
    background-color: #7ab434;
}

.easyship .int-header-right:after {
    background-color: #8CD3DB;
}

.clickpost .int-header-right:after {
    background-color: #007aff;
}

.unicommerce .int-header-right:after {
    background-color: #4786ed;
}

.easycom .int-header-right:after {
    background-color: #1e9f44;
}

.omsguru .int-header-right:after {
    background-color: #0e76bc;
}

.vineretail .int-header-right:after {
    background-color: #ec1c24;
}

.whatsapp .int-header-right:after {
    background-color: #25d366;
}

.int-body {
    display: flex;
    margin-block: 1rem;
    gap: 1rem;
}

.int-body>section {
    width: 50%;
    padding: 1rem;
}

.int-checkbox {
    display: flex;
    flex-direction: column;
}

.int-checkbox label {
    width: fit-content;
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.int-checkbox label input {
    min-width: unset;
    width: auto;
}

.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    list-style: none;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #d3e5b3;
    top: 11px;
    bottom: 0;
    left: 28px;
    margin-left: -3px;
    height: 84%;
}

.magento .timeline::after {
    background-color: #fdd1b4;
    height: 94%;
}

.amazon .timeline::after {
    background-color: #fddeb5;
}

.hippo .timeline::after {
    background-color: #c0d4d2;
}

.woo .timeline::after {
    background-color: #E4DBEF;
    height: 88%;
}

.easyship .timeline::after {
    background-color: #bce5ea;
    height: 94%;
}


.unicommerce .timeline::after {
    background-color: #c7daf7;
    height: 77%;
}

.easycom .timeline::after {
    background-color: #8fc9c5;
    height: 85%;
}

.omsguru .timeline::after {
    background-color: #b5d2e9;
    height: 88%;
}

.clickpost .timeline::after {
    background-color: #94c7ff;
}

.vineretail .timeline::after {
    background-color: #ead4dc;
    height: 90%;
}

.whatsapp .timeline::after {
    background-color: #ead2da;
    height: 90%;
}

.timeline-list {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 100%;
}

.timeline-list:after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    left: -1rem;
    background-color: white;
    border: 4px solid #95bf47;
    top: 9px;
    border-radius: 50%;
    z-index: 1;
}

.magento .timeline-list:after {
    border: 4px solid #f36306;
}

.amazon .timeline-list:after {
    border: 4px solid #F8991D;
}

.hippo .timeline-list:after {
    border: 4px solid #7ab434;
}

.woo .timeline-list:after {
    border: 4px solid #7f54b3;
}

.easyship .timeline-list:after {
    border: 4px solid #8CD3DB;
}


.unicommerce .timeline-list:after {
    border: 4px solid #4786ed;
}

.easycom .timeline-list:after {
    border: 4px solid #1e9f44;
}

.omsguru .timeline-list:after {
    border: 4px solid #0e76bc;
}

.clickpost .timeline-list:after {
    border: 4px solid #007aff;
}

.vineretail .timeline-list:after {
    border: 4px solid #ec1c24;
}

.whatsapp .timeline-list:after {
    border: 4px solid #f47374;
}

.whatsapp .whatsapp-benefits {
    list-style: none;
    display: flex;
    text-align: center;
    margin-block: 20px;
    width: 100%;
    justify-content: space-around;
}

.whatsapp .whatsapp-benefits li {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    position: relative;
}

.whatsapp .whatsapp-benefits li.plus-middle {
    font-size: 50px;
    letter-spacing: 12px;
    /* color: var(--sh-primary-middle); */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.whatsapp .whatsapp-benefits li.plus-middle hr {
    min-height: 150px;
    height: 100%;
    width: 1px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, #fff 0%, #60a9eb 25%, #60a9eb 75%, #fff 100%);
    border-top: none;
    padding: 0;
    opacity: 1;
    margin: 0px;
}

/* .whatsapp .whatsapp-benefits li:after {
    content: "---+---";
    position: absolute;
    bottom: 50%;
    right: -100%;
    font-size: 50px;
    letter-spacing: 12px;
    color: var(--sh-primary);
}

.whatsapp .whatsapp-benefits li:nth-of-type(3)::after {
    display: none;
} */

.whatsapp .whatsapp-get-started {
    width: 100%;
    position: relative;
    /* max-width: 320px; */
}

.whatsapp .whatsapp-get-started input {
    width: 100%;
    border: 1px solid var(--sh-primary-light);
    border-radius: 0.5rem;
    height: 36px;
    padding-inline: 10px;
}

.whatsapp .whatsapp-get-started button {
    position: absolute;
    right: 0px;
    height: 36px;
    background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
    color: #fff;
    border: none;
    outline: none;
    font-size: 0.8rem;
    border-radius: 0 0.5rem 0.5rem 0;
}

.color-whatsapp {
    color: #25d366;
}

.whatsapp .try-it-out {
    background: url("../../../assets//image/whatsappBG.jpg");
    /* background: var(--sh-primary-light); */
    padding: 15px;
    border-radius: 0.5rem 0 0 0.5rem;
    width: 100%;
    /* border: 1px solid #e1e1e1; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 35px;
    position: relative;
}

.whatsapp .try-it-out::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #1975c975;
    background: #25d36636;
    z-index: 0;
    border-radius: 0.5rem;
}

.whatsapp .pricing-section {
    padding: 15px;
    /* border: 1px solid #e1e1e1; */
    border-left: none;
    border-radius: 0 0.5rem 0.5rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul.additional-features-nav {
    list-style: none;
    display: flex;
    padding: 0px;
    height: 30px;
    margin-bottom: 0px;
    margin-top: 45px;
}

ul.additional-features-nav li {
    cursor: pointer;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid grey;
}

ul.additional-features-nav li.active {
    border-bottom: 2px solid;
    font-weight: 600;
}

.additional-features {
    min-height: 25rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
}

.whatsapp .chat-window {
    position: relative;
    /* overflow: hidden; */
}

.chat-window img {
    position: absolute;
    /* bottom: -114px; */
    width: 60%;
    left: 20%;
    top: 50px;
    border-radius: 0.5rem;
    /* box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.1) !important; */
    -webkit-box-shadow: 0px 10px 11px 7px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 0px 10px 11px 7px rgba(0, 0, 0, 0.51);
    box-shadow: 0px 10px 11px 7px rgba(0, 0, 0, 0.51);
}

.easycom .int-form label {
    position: relative;
}

.easycom .int-form label .copy-click {
    background-color: var(--sh-primary-middle);
    position: absolute;
    right: 0;
    bottom: 0;
    height: 36px;
    width: 30px;
    border-radius: 0 0.5rem 0.5rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    border: none;
}

.easycom .int-form label .copy-click:hover {
    box-shadow: 0 0px 2px 1px rgba(13, 110, 253, .25);
}

.easycom .int-form label .copied-click {
    position: absolute;
    top: -3px;
    right: -18px;
    white-space: nowrap;
    font-size: 12px;
    background: #fff;
    box-shadow: 0 0px 2px 1px rgba(13, 110, 253, .25);
    padding: 2px 5px;
    border-radius: 0.5rem;
    z-index: 1;
}

.whatsapp .preview-icon-btn {
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
}

.whatsapp .preview-section {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
}

.whatsapp .preview-section img {
    width: auto;
    height: 90%;
    z-index: 6;
    border: 3px solid #075e55;
    border-radius: 1.5rem;
}

.whatsapp .hand-arrow {
    font-size: 12px;
    position: absolute;
    white-space: nowrap;
    top: 16px;
    left: 97%;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.whatsapp .hand-arrow span {
    animation: moveRightToLeft 0.7s linear infinite;
    display: inline-block;
}

@keyframes moveRightToLeft {
    0% {
        transform: translateX(10%);
        /* Start off-screen to the right */
    }

    100% {
        transform: translateX(-10%);
        /* Move off-screen to the left */
    }
}