.tracking-header {
    width: 100%;
    background-color: #CEDBF2;
    padding: 10px 20px;
    min-height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tracking-body {
    width: 100%;
    padding: 30px;
}

.tracking-header p,
.tracking-body p,
.tracking-header h4,
.tracking-body h4 {
    margin-bottom: 0;
}

.tracking-header img {
    width: 45px;
    height: 45px;
}

.tracking-body ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: calc(100vh - 139px);
    overflow: auto;
}

.tracking-body ul li {
    display: flex;
    align-items: start;
    gap: 10px;

}

.tracking-body ul li .tracking-status {
    position: relative;
    height: 100%;
    overflow-y: clip;
}

.tracking-body ul li .tracking-status::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 2px;
    top: 34px;
    left: -22px;
    border-left: 1px dashed #000;
    z-index: -1;
}

.tracking-body ul li:last-of-type .tracking-status::after {
    display: none;
}

.tracking-body ul li h4 {
    font-size: 1rem;
    font-weight: 600;
}

.tracking-body ul li.active h4 {
    color: green;
    font-size: 1.2rem;
    font-weight: 500;
}

.tracking-body ul li.active .track-icon {
    margin-top: 2px;
}

.tracking-body ul li.active .track-icon svg path {
    fill: green;
}

.tracking-body .ant-skeleton-paragraph {
    height: auto;
}