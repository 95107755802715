.sidepanel-header {
    width: 100%;
    background-color: #CEDBF2;
    padding: 15px 20px;
}

#sidepanel-closer {
    position: absolute;
    top: 45%;
    width: 40px;
    height: 40px;
    display: flex;
    left: -19px;
    background-color: #fff;
    border-radius: 120px;
    /* padding: 11px; */
    /* border: 1px solid; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sidepanel-body {
    margin-block: 20px;
    margin-inline: 20px;
    position: relative;
    height: calc(100vh - 140px);
}

/* .sidepanel-body form{
    display: flex;
    flex-direction: column;
    gap: 15px;
} */

.form-input-fields {
    max-height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
}

.filter-row {
    display: flex;
    gap: 15px;
    width: 100%;
}

.filter-row label {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.more-filters-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 36px;
    overflow-y: hidden;
}

.more-filters-footer label {
    display: flex;
    align-items: center;
    gap: 10px;
}

.more-filters-footer .filter-name-ip {
    width: 155px;
    min-width: unset;
    animation: inputAppear 1s ease;
}

@keyframes inputAppear {
    from {
        width: 0px;
    }

    to {
        width: 150px;
    }
}

.morefilters-panel .rmsc .dropdown-container {
    background-color: transparent;
    border: 1px solid var(--sh-primary-middle);
    border-radius: 0.5rem;
    padding-left: 10px;
}

.morefilters-panel .rmsc .dropdown-content {
    width: 90%;
}

.morefilters-panel .form-input-fields {
    overflow-x: clip;
}

.morefilters-panel .css-b62m3t-container:focus-visible {
    outline: none;
}

.morefilters-panel .css-13cymwt-control {
    border-color: var(--sh-primary-middle);
    border-radius: 0.5rem;
}

.morefilters-panel .sku-checkbox label {
    display: flex;
    gap: 7px;
    flex-direction: row;
}

.morefilters-panel .date-picker-container .react-datepicker-popper {
    left: -19px !important;
}