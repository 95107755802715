.orders-menu-header ul {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  padding: 0;
}

.orders-menu-header ul li {
  padding: 5px 7px;
  color: rgba(0, 0, 0, .55);
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.orders-menu-header .form-select {
  width: 320px;
  color: rgba(0, 0, 0, .55);
  background-color: #fff;
  border-radius: 0.2rem;
  /* font-weight: 700; */
  font-size: 16px;
  padding: 5px 7px;
  border-radius: 4px;
}

.orders-menu-header .form-select * {
  padding-block: 15px;
}

.down-sliding-select {
  position: relative;
  width: 10rem;
}

.selected-option {
  padding: 5px 7px;
  border: 1px solid #9ac2e5;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #111;
  border-radius: 0.5rem;
}

/* 
.awb-tracking-slider {
  position: fixed;
  width: 40em;
  height: 100vh;
  top: 0;
  right: -50em;
  background: #fff;
  transition: 1s;
  z-index: 6;
} */
/* 
.awb-tracking-slider.open {
  right: 0;
} */



.option {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  font-size: 14px;
}

.option:hover {
  background-color: #f0f0f0;
}

/* .option.selected {
    background-color: #1975C9;
    color: #fff;
  } */

/* .down-sliding-select:hover .options-container {
    max-height: 100px; 
  } */



.all-orders td {
  background-color: #fff;
}


.Searchbox-label {
  position: relative;
}

.Searchbox-label .search-icon {
  position: absolute;
  top: 7px;
  left: 5px;
}

.Searchbox-label input {
  padding-left: 26px;
}

.table-container {
  height: calc(100vh - 264px);
  overflow-y: auto;
}

.sticky-header {
  position: sticky;
  top: 0;
  /* background-color: #ffffff; */
  z-index: 1;
}

.table-row td,
.table-row th {
  background-color: #fff;
  padding: 0.5rem;
  border-bottom: none;
}

.table-row .checkbox-cell {
  min-width: 10px;
}

.table-row td {
  font-size: 14px;
}

.table-row td:first-child,
.table-row th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.table-row td:last-child,
.table-row th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.blank-row td {
  padding: 7px;
}

/* tbody tr.table-row:nth-of-type(4n-3) td{
  background-color: rgb(25 117 201 / 52%);
 } */


.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}

.filter-container .nav-actions-container:hover .nav-actions-list {
  width: 170px;
  height: 115px;
}

.search-container {
  width: 50%;
}

.search-container label {
  position: relative;
  width: 100%;
}

.search-container input:focus-visible {
  outline: none;
}

.search-container p {
  margin-bottom: 0;
}

.checkbox-cell input {
  vertical-align: -1px;
}

.filter-container .button-container {
  display: flex;
}

.side-panel {
  position: fixed;
  top: 0;
  right: -50em;
  transition: 1s;
  height: 100vh;
  width: 41em;
  background-color: #fff;
  z-index: 6;
}

.backdrop {
  position: fixed;
  background-color: #111111b8;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5;
}

.cell-inside-box {
  display: flex;
  flex-direction: column;
}

.cell-inside-box p {
  margin-bottom: 0;
  font-size: 14px;
}

.cell-inside-box .anchor-awb {
  border-bottom-style: dotted;
  width: fit-content;
}

.order-Status-box {
  background-color: #c5dcf1;
  padding: 4px 10px;
  color: #1975C9;
  font-size: 12px !important;
  width: fit-content;
  border-radius: 10px;
  text-transform: capitalize;
  margin-bottom: 0;
  font-weight: 700;
  white-space: nowrap;
}

.status-box .order-Status-box {
  width: 100%;
  text-align: center;
}

.icon-rotate {
  transform: rotate(180deg);
}

.action-options {
  position: relative;
}

.action-options .threedots-img {
  justify-content: center;
  display: flex;
  background-color: #c5dcf1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.action-options .threedots-img img {
  transform: rotate(90deg);
  transition: 0.3s;
}

.action-options.bulk-actions {
  z-index: 2;
}


.action-list ul li {
  padding-block: 4px;
  cursor: pointer;
}

.action-list ul li hr {
  margin-block: 0;
  margin-left: -8px;
  width: 127px;
}

.action-options:hover img {
  transform: rotate(0deg);
}

.action-options:hover .action-list {
  max-height: 361px;
  background-color: #fff;
  width: 150px;
  z-index: 1;
}

.info-missing-content {
  color: #F31429;
}

.confirmation-modal.split-order-popup .modal-dialog {
  margin-top: 5%;
  max-width: 460px;
}


.confirmation-modal.split-order-popup table th,
.confirmation-modal.split-order-popup table td {
  text-align: start;
}

.confirmation-modal.split-order-popup table td {
  padding-block: 7px;
}

.confirmation-modal.split-order-popup .modal-footer {
  justify-content: space-between;
  align-items: flex-end;
}



@media screen and (max-width:1366px) {
  .cell-inside-box p {
    font-size: 12px;
  }
}