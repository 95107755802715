.bulk-action-container {
    position: absolute;
    width: 90%;
    width: fit-content;
    height: auto;
    bottom: 64px;
    left: 5%;
    box-shadow: 0 0 18px 10px rgba(0, 0, 0, 0.1) !important;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.ba-inner-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.ba-rows-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 16px;
    background: var(--sh-primary-light);
    color: var(--sh-primary);
    width: auto;
    white-space: nowrap;
    border-radius: 0.5rem 0 0 0.5rem;
}

.ba-actions {
    list-style: none;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    gap: 54px;
    margin-bottom: 0;
    padding-right: 20px;
    align-items: center;
}

.ba-actions li {
    position: relative;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 24px;
}

.ba-actions li span {
    font-size: 0.8rem;
}

.ba-actions li::after {
    content: '';
    position: absolute;
    background: #ddd;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(128, 128, 128, 0.4290091036414566) 50%, rgba(255, 255, 255, 1) 100%);
    height: 70%;
    width: 1px;
    right: -27px;
    top: 15%;
}

.ba-actions li:last-of-type::after {
    display: none;
}