.edit-order-section {
    position: fixed;
    height: 100vh;
    background-color: #fff;
    top: 0;
    right: -100vw;
    z-index: 6;
    transition: 1s;
    width: 59vw;
    min-width: 331px;
}

.edit-order-section.open-edit {
    right: 0;
}

.edit-order-header {
    padding: 30px;
    background-color: var(--sh-primary-light);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit-order-body {
    /* padding-block: 20px;
    padding-inline: 30px 20px; */
    height: calc(100vh - 100px);
    overflow-y: scroll;
    display: flex;
}

.edit-order-body .navigation-side {
    width: 30%;
    background-color: #f4f8fb;
}

.edit-order-body .details-side {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.edit-order-body .details-side .details-component .cof-btn-container {
    display: none !important;
}

.edit-order-body .details-side .details-component {
    /* height: calc(100vh - 188px); */
    /* overflow-y: scroll; */
    width: 100%;
}

.edit-order-body .details-side .details-component .shadow-sm {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.edit-order-body .details-side .details-component .inputs-container {
    width: 90%;
}

.edit-order-body .details-side .details-component .warehouse-options .sm-warehouse-item {
    width: 50%;
}

.edit-order-body .navigation-side ul {
    list-style: none;
    padding-left: 0;
}

.edit-order-body .navigation-side ul li {
    cursor: pointer;
    padding: 13px 30px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 51px;
}

.edit-order-body .navigation-side ul li.active {
    background-color: #ddd;
}

.edit-order-body label {
    position: relative;
}

.edit-order-body span.unit {
    align-items: center;
    background: var(--sh-primary-light);
    background: #dfdfdf;
    border-radius: 0 .5rem .5rem 0;
    bottom: 1px;
    display: flex;
    height: 34px;
    justify-content: center;
    position: absolute;
    right: 1px;
    width: 36px;
    font-weight: 400;
    font-size: 1rem;
}

.edit-order-body span.unit.pd-lbh {
    right: 13px;
}