.page-settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.customization-form {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 13px 4px rgba(0, 0, 0, 0.1);
}


.customization-form .menu-item {
    margin-bottom: 15px;
    display: flex;
    gap: 15px;
    align-items: flex-end;
}

.customization-form .menu-item>* {
    width: 100%;
}

.customization-form .menu-item button {
    background: #f1cbcf;
    color: #FC3B3B;
    margin: 0;
    padding: 0;
    width: 36px;
    height: 36px;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customization-form input[type="checkbox"] {
    margin-right: 7px;
}

.customization-form input[type="file"] {
    margin-top: 0px;
}

.customization-form .two-column-inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 15px;
}

.customization-form .two-column-inputs label {
    width: calc(50% - 10px);
}

.page-settings-main {
    display: flex;
    gap: 1.2rem;
    width: 100%;
}

.tracking-form-container {
    width: calc(50% - 116px);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.live-preview {
    margin-top: 5px;
    width: 57%;
}

.save-button-container {
    margin-top: 20px;
}

.add-banner-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.subdomain-name {
    padding-left: 64px !important;
}

.domain-prefix {
    position: absolute;
    top: 21px;
    left: 1px;
    /* background: var(--sh-primary-light); */
    color: var(--sh-primary);
    height: 34px;
    border-radius: 0.5rem 0 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    padding-left: 10px;
}

.domain-suffix {
    position: absolute;
    top: 21px;
    right: 1px;
    /* background: var(--sh-primary-light); */
    color: var(--sh-primary);
    height: 34px;
    border-radius: 0 0.5rem 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
}