.wallet-container {
    position: fixed;
    /* top: -50em; */
    top: 2%;
    right: -40em;
    /* right: 2%; */
    width: fit-content;
    height: auto;
    z-index: 6;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wallet-container.show {
    top: 2%;
    right: 2%;
}

.wallet-box {
    width: 27.5rem;
    height: auto;
    background: var(--sh-primary-light);
    position: relative;
    border-radius: 0.5rem;
}

.wallet-container .close-button {
    text-align: end;
    font-size: 1.5rem;
    position: absolute;
    right: 0px;
    top: 0px;
}

.wallet-container .close-button:active {
    color: #fff;
}

.wallet-container .wallet-inner-bg {
    background-color: #fff;
    margin: 1rem;
    border-radius: 0.5rem;
    height: calc(100% - 20px);
}

.balance-container {
    width: 100%;
    margin-inline: auto;
    /* height: 128px; */
    background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, #1975c9 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding-top: 10px;
    position: relative;
    border-radius: 0.5rem 0.5rem 0 0;
    margin-bottom: 1.2rem;
    padding-bottom: 15px;
}

.balance-amount {
    display: flex;
    align-items: start;
    gap: 5px;
}

.balance-amount p:first-of-type {
    margin-top: 6px;
}

.redeem-field {
    border-radius: 0.5rem 0 0 0.5rem;
}

.redeem-button {
    position: absolute;
    right: 10px;
    cursor: pointer;
}
