.input-field-error {
    border: 1px solid red !important;
}

.custom-error,
.mandatory {
    font-size: 12px;
    color: #FC3B3B !important;
}

.create-order-flow-container span.unit.pd-lbh {
    right: 0.8125rem;
    bottom: 1px;
}