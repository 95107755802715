.support-page .table-container {
    height: calc(100vh - 287px);
}


.support-page .action-options {
    width: fit-content;
}

.support-page .action-options:hover .action-list {
    width: 110px;
    border-radius: 4px;
}

.support-page .action-options:hover .action-list ul li {
    cursor: pointer;
}

.support-nav {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    justify-content: space-between;
    width: 100%;
}

.support-left-section {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
}

.support-left-section::after {
    background-color: #ddd;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0px;
    content: '';
    z-index: 1;
}

.support-left-section div {
    padding: 5px 10px;
    border: 2px solid transparent;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
}

.support-left-section div.active {
    background-color: #fff;
    border-left: 2px solid var(--sh-primary);
    border-top: 2px solid var(--sh-primary);
    border-right: 2px solid var(--sh-primary);
    border-bottom: 2px solid #fff;
    z-index: 2;
}

.support-right-section {
    display: flex;
    gap: 15px;
    align-items: center;
}

.support-right-section .search-container .support-choice-card {
    position: relative;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid var(--sh-primary);
    border-radius: 4px;
    box-shadow: inset 0 0 0 0 var(--sh-primary);
}

.support-choice-card.active {
    color: white;
    box-shadow: inset 0 -100px 0 0 var(--sh-primary);
}

.support-choice-card:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 var(--sh-primary);
}

.support-choice-card:active {
    transform: scale(0.9);
}

.support-right-section .search-container {
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: relative;
    color: #111;
    width: 350px;
}

.support-right-section .search-container label {
    position: relative;
}

.support-right-section .search-container input {
    height: 36px;
    padding-left: 10px;
}

.support-right-section .search-container button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none;
    cursor: pointer;
    background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
    color: #fff;
    width: 36px;
    height: 36px;
    border-radius: 0 0.5rem 0.5rem 0;
}

.support-right-section .search-container input:focus~label {
    transition: 0.3s;
    padding-left: 2px;
    transform: translateY(-28px);
}

.ticket-slider {
    position: fixed;
    top: 0;
    right: -70em;
    transition: 1s;
    height: 100vh;
    width: 30em;
    background-color: #fff;
    z-index: 6;
}

.ticket-slider.open {
    right: 0;
}

.support-page table {
    margin-top: 0px;
}

.support-page table tr td {
    padding-block: 10px;
}

.support-page table tr.blank-row td {
    padding-block: 7px;
}

.ticket-filter-inputs hr {
    margin-block: 0 1rem;
}

.ticket-filter-inputs {
    display: flex;
    flex-direction: column;
    gap: 18px;
    /* padding-inline: 15px; */
}

.ticket-filter-inputs .css-1nmdiq5-menu {
    z-index: 2;
}

.ticket-filter-inputs .css-13cymwt-control {
    height: 30px;
    border: 1px solid var(--sh-primary-middle);
    /* background-color: var(--sh-primary-light); */
    min-width: 100%;
    padding-left: 10px;
    border-radius: 4px;
}

.ticket-slider-header {
    padding: 30px;
    background-color: var(--sh-primary-light);
}

.ticket-slider-body {
    padding-block: 20px;
    padding-inline: 30px 20px;
}

.ticket-form-row {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 5px;
}

.ticket-form-btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.text-field {
    min-height: 100px;
}

.form-control.choose-file-container {
    padding-left: 5px;
    padding-top: 7px;
}


.help-button {
    position: absolute;
    right: 0px;
    top: 10px;
    color: #fff;
    background-color: #3BB54B;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 50px 0 0 50px;
    display: flex;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
}

.help-button p {
    line-height: 15px;
    font-size: 14px;
}

.Tickets-table tr th:last-of-type {
    width: 9%;
}

.ticket-description p {
    margin-bottom: 0;
}

.ticket-view-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.status-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ticket-status {
    padding: 2px 10px;
    background-color: #3BB54B;
    color: #fff;
    font-size: 12px;
    border-radius: 50px;
    font-weight: 700;
    text-transform: uppercase;
}

.comments-section {
    height: 100%;
    width: 100%;
    border-radius: 4px;
}

.chat-container {
    display: flex;
    flex-direction: column;
    background-color: #F2F2F2;
    min-height: 100%;
    padding: 10px;
    height: calc(100vh - 415px);
    overflow: auto;
    border-radius: 0.5rem;
}

.user-comment {
    background-color: #dcf8c6;
    padding: 8px;
    margin: 4px 8px;
    border-radius: 8px;
    align-self: flex-end;
    max-width: 80%;
}

.support-comment {
    background-color: #fff;
    padding: 8px;
    margin: 4px 8px;
    border-radius: 8px;
    align-self: flex-start;
    max-width: 80%;
}

.new-comment-input form {
    display: flex;
}

.new-comment-input input {
    min-width: unset;
    height: 36px;
    width: -webkit-fill-available;
}

.attachment-container {
    position: fixed;
    top: 10%;
    left: 30%;
    width: 100%;
    max-width: 600px;
    z-index: 6;
    margin: 0 auto;
}

.attachment-container img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.view-attachment,
.download {
    cursor: pointer;
}

.comment-container {
    position: relative;
    padding-bottom: 24px;
    min-width: 150px;
}

.comment-container p {
    position: absolute;
    bottom: 0;
    right: 8px;
    font-size: 0.8rem;
    margin-bottom: 2px;
}

.slider-scroll-body {
    max-height: calc(100vh - 173px);
    overflow-y: auto;
    padding-bottom: 20px;
}

.attachment-file-unsupported {
    background-color: #4c494c;
    border-radius: 12px;
    color: #fff;
    margin-bottom: 40px;
    padding: 20px;
    text-align: center;
    -webkit-box-shadow: 0px 10px 12px 5px rgba(0, 0, 0, .2);
    box-shadow: 0px 10px 12px 5px rgba(0, 0, 0, .2);
}

.react-datepicker__close-icon::after {
    background-color: transparent;
    content: '\00d7';
    font-size: 20px;
    font-weight: 600;
    color: var(--sh-primary);
    vertical-align: bottom;
}

.support-page .nav-actions-container:hover .nav-actions-list {
    width: 160px;
}