#navTabs .alignContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.alignContent a {
    text-decoration: none;
}

.navbar-expand .navbar-nav .nav-link {
    padding: 5px 7px;
}

.navbar-expand .navbar-nav .nav-link.active {
    /* background-color: #1975C9; */
    background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
    color: #fff;
    border-radius: 4px;
}

.nav-actions-container {
    position: relative;
}

.nav-actions-container .nav-action-dots {
    transform: rotate(90deg);
}

.nav-actions-container .nav-actions-list {
    list-style: none;
    background: #fff;
    position: absolute;
    transition: 0.7s;
    right: 0;
    width: 0px;
    height: 0px;
    top: 30px;
    z-index: 2;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    background-color: #eaf2fa;
    box-shadow: 8px 8px 7px rgba(0, 0, 0, 0.1) !important;
    border-radius: 0.5rem;
}

.nav-actions-container:hover .nav-actions-list {
    width: 140px;
    height: 160px;
}

.nav-actions-container .nav-actions-list ul {
    display: flex;
    gap: 12px;
    margin-inline: 1rem;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
}

.nav-actions-container .nav-actions-list ul li {
    margin-bottom: 0;
    padding: 8px;
    border-radius: 0.5rem;
    background-color: #ffffff;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding-left: 20px;
    justify-content: flex-start;
    cursor: pointer;
    border: 1px solid transparent;
}

.nav-actions-container .nav-actions-list ul li svg {
    font-size: 1.4rem;
}

.tab-counter {
    background: var(--sh-primary-light);
    color: var(--sh-primary);
    transition: transform 0.3s ease, font-size 0.3s ease;
    height: 20px;
    border-radius: 7px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 4px;
    font-size: 11px;
}

.active .tab-counter {
    background: #fff;
}

.tab-counter:hover {
    transform: scale(1.3);
}