.tracking-page-preview {
    border: 2px solid #000;
    margin: 0 auto;
    text-align: center;
    min-height: auto;
    font-size: 12px;
    border-radius: 1rem;
    overflow: auto;
}

.tracking-page-preview .title {
    font-size: 21px;
    margin-bottom: 8px;
}

.brand-logo {
    width: auto;
    height: auto;
    max-width: 120px;
    max-height: 35px;
}

.banner-preview {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    max-height: calc(100vh - 279px);
}

.banner-preview img {
    width: 100%;
    height: 100%;
}

.banner-mobile {
    display: none;
}

.menu-nav {
    background: #fff;
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    /* z-index: 9; */
}

.footer-nav {
    background: #fff;
    width: 100%;
    padding-block: 7px;
    padding-inline: 10px;
}

.menu-nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
    margin-bottom: 0;
    padding: 0;
}

.footer-nav ul {
    list-style: none;
    display: flex;
    margin-inline: auto;
    justify-content: flex-end;
    margin-bottom: 0px;
    gap: 20px;
    flex-wrap: wrap;
}

.menu-nav ul li {
    text-transform: capitalize;
    display: inline;
    font-size: 0.8rem;
}

.footer-nav ul li {
    text-transform: capitalize;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .banner-desktop {
        display: none;
    }

    .banner-mobile {
        display: block;
    }
}

.tracking-info-preview {
    display: flex;
    gap: 20px;
    width: 100%;
    max-height: 526px;
    justify-content: space-between;
    margin-top: 50px;
    padding-inline: 20px;
}

.tracking-info-preview hr {
    height: 100%;
    width: 1px;
    min-height: 526px;
    background: #000;
    border-color: transparent;
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.75);
    margin: 0;
}


/* footer */


/* Footer styles */
.footer {
    background-color: #fff;
    color: #000;
    padding: 12px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    /* position: sticky; */
    box-shadow: 4px 0 12px rgba(0, 0, 0, 0.1);
    bottom: 0;
    z-index: 9;
    margin-top: 38px;
}

.support-info {
    gap: 10px;
    display: flex;
    align-items: center;
}

.support-info p {
    margin-bottom: 0;
    font-size: 0.8rem;
}

.powered-by {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
}

.powered-by img {
    height: 18px;
}

.footer a {
    font-size: 0.8rem;
    color: inherit;
}