.chartContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 16px;
}

.infoCardIconContainer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    box-sizing: border-box;
}

.alignWord {
    /* width: 85px; */
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

.h-160 {
    height: 160px;
}

.card-height {
    position: relative;
}

.card-footer {
    position: absolute;
    bottom: 10px;
}

.datatable-container {
    background: white;
    padding: 7px;
    border-radius: 10px;
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
    background-color: #dbdbdb;
    padding: 0;
    height: 30px;
    width: 30px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}