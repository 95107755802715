.add-sku-modal .modal-dialog {
    margin-top: 5%;
}

.confirmation-modal.impurt-sku .modal-dialog {
    margin-top: 10%;
}

.add-sku-modal .modal-header {
    font-weight: 500;
    padding: 15px;
    background: var(--sh-primary-light);
    border-radius: 0.5rem 0.5rem 0 0;
    color: var(--bs-heading-color);
    border-bottom: none;
    justify-content: center;
}

.add-sku-modal .modal-header .modal-title {
    font-size: 1.25rem;
}

.add-sku-modal .modal-footer,
.confirmation-modal.impurt-sku .modal-footer {
    border-top: none;
    justify-content: end;
}

.add-sku-modal .modal-footer {
    justify-content: space-between;
}


.add-sku-modal .modal-body,
.confirmation-modal.impurt-sku .modal-body {
    margin-block: 15px 3px;
    padding: 0 15px;
    text-align: unset;
}

.add-sku-modal .modal-dialog {
    max-width: 1140px;
    min-width: 320px;
}

.sku-details-form-input {
    padding-top: 6.5px;
}