.label-customize-page {
    padding-bottom: 15px;
    /* max-height: calc(100vh - 89px); */
}

.label-customize-page p {
    margin-bottom: 0;
}

.label-customize-page table,
.label-customize-page td,
.label-customize-page th {
    text-align: left;
    border: 1px solid black;
    border-collapse: collapse;
}

.label-customize-page .tableInner {
    border: 0;
}

.label-customize-page table {
    width: 100%;
    border-collapse: collapse;
}

.label-customize-page .page-break {
    display: block;
    page-break-after: always;
}

/* @page {
    size: A6;
    margin: 10px;
} */

.label-customize-page .no_row {
    border: 0;
}

.label-customize-page .noPadding {
    padding: 0;
}

.label-customize-page th,
.label-customize-page td {
    padding: 3px;
}


.lc-section-column {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
}


.lc-section-item {
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
}

.lc-section-header {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 10px 15px;
    background: var(--sh-primary-light);
    border-radius: 0.5rem 0.5rem 0 0;
    justify-content: space-between;
}

.lc-section-body {
    padding: 15px;
}

.lc-section-body ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.lc-section-body ul li {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.lc-section-item .react-toggle-track {
    width: 34px;
    height: 17px;
}

.lc-section-item .react-toggle-thumb {
    width: 12px;
    height: 12px;
    top: 2px;
    left: 3px;
}

.lc-section-item .react-toggle--checked .react-toggle-thumb {
    left: 19px;
}

#label-product-detail.active tr {
    border-bottom: none;
}

#label-product-detail.active th,
#label-product-detail.active td {
    border: none;
}