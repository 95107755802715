.billing-count-container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.billing-count-container .count-card {
    height: 50px;
    /* width: 100%; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 7px;
    align-items: center;
    flex: 1 1;
    max-width: 356px;
}

.billing-count-container p {
    margin-bottom: 0;
    font-size: 0.9rem;
    display: flex;
    gap: 8px;
    align-items: center;
    white-space: nowrap;
}

.count-card span {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--sh-primary);
}

.invoiceTab .count-card {
    cursor: pointer;
    width: 245px;
    padding: 7px;
    height: 50px;
    border: 2px solid transparent;
}

.invoiceTab .count-card p {
    font-size: large;
}

.invoiceTab .count-card.selected {
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, var(--sh-primary), var(--sh-primary-middle)) border-box;
    border-radius: 0.5em;
    box-sizing: border-box
}

.billing-page-container .table-container {
    height: calc(100vh - 281px);
}

.billing-page-container .table-container.cr-table-height {
    height: calc(100vh - 203px);
}

.remitance-logs-filter .form-input-fields {
    min-height: 400px;
    /* max-height: unset; */
}

/* 
.remitance-logs-filter .form-input-fields .css-1nmdiq5-menu {
    height: 200px;
    overflow: scroll;
} */

.remitance-logs-filter .sidepanel-tabs {
    display: flex;
}

.remitance-logs-filter .sidepanel-tabs p {
    width: 100%;
    border: 1px solid var(--sh-primary-middle);
    text-align: center;
    padding: 5px 10px;
    font-size: 1.2rem;
    cursor: pointer;
}

.remitance-logs-filter .sidepanel-tabs p.active {
    background: var(--sh-primary-middle);
    color: #fff;
}

.remitance-logs-filter .sidepanel-body {
    height: calc(100vh - 172px);
}

.remitance-logs-filter .section-divider {
    position: relative;
}

.remitance-logs-filter .section-divider span {
    position: absolute;
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    top: 0;
    left: 47%;
}