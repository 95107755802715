.signup-section {
  position: fixed;
  /* background-image: url(../../../assets/image/loginBg.png);
  background-repeat: repeat-y;
  background-size: contain; */
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
}

.signup-section .signup {
  height: 100vh;
  position: relative;
}

.signup-section .signup .signup-logo-container {
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  width: fit-content;
}

.signup-section .signup .signup-logo-container::before {
  /* content: "";
  display: flex;
  position: fixed;
  width: 22rem;
  height: 22rem;
  background: linear-gradient(135deg, var(--primary-10), var(--primary-0));
  border-radius: 77px;
  bottom: -11rem;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  z-index: -1; */
  content: "";
  display: flex;
  position: fixed;
  width: 22rem;
  height: 22rem;
  background: linear-gradient(135deg, var(--bs-white), var(--bs-white));
  border-radius: 77px;
  top: -225px;
  left: 69px;
  transform: translateX(-50%) rotate(306deg);
  z-index: 0;
}

.signup-section .signup .signup-logo-container img {
  height: 24px;
  z-index: 2;
  position: absolute;
  top: 2.5rem;
  left: 1.5rem;
}

.signup-section .left-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: url('../../../assets/image/bg_login.png');
  background-size: cover;
  background-position: center;
}

.signup-section .left-side img {
  width: 87%;
  height: auto;
  /* margin-right: -70px; */
}

.signup-section .right-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  background: url(../../../assets/image/leftSidePattern.svg);
  background-position: center;
  background-size: contain;
}

.signup-section .signup .content {
  padding: 20px 48px;
  min-width: 35.6rem;
  min-height: 63vh;
  background: #fff;
  position: relative;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: column;
  gap: 25px;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  margin-right: 80px;
  background-color: #fff;
}


.signup-section .signup .content h2 {
  font-size: 2em;
  color: var(--sh-primary);
  text-transform: uppercase;
}

.signup-section .signup .content .form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.signup-section .signup .content .form .inputBox {
  position: relative;
  width: 100%;
}

.signup-section .signup .content .form .inputBox input {
  position: relative;
  width: 100%;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1em;
}

.signup-section .signup .content .form .inputBox i {
  position: absolute;
  left: 0;
  padding: 6px 10px;
  font-style: normal;
  color: #aaa;
  transition: 0.5s;
  pointer-events: none;
}

.signup-section .signup .content .form .inputBox input:valid~i,
.signup-section .signup .content .form .inputBox input:focus~i {
  transform: translateY(-1rem);
  font-size: 0.8em;
  color: transparent;
  user-select: none;
}

.signup-section .signup .content .form .links {
  position: relative;
  width: 100%;
  display: flex;
  font-size: 0.8rem;
}

.signup-section .signup .content .form .links button {
  font-size: 0.8rem;
}

.signup-section .signup .content .form .links a {
  /* color: var(--sh-primary); */
  text-decoration: none;
}

.signup-section .signup .signup-text button {
  color: var(--sh-primary);
}

.signup-section .signup .user-role-select {
  display: flex;
  gap: 10px;
}

.signup-section .signup .user-role-select label {
  width: auto;
  border: 1px solid var(--sh-primary-light);
  border-radius: 0.5rem;
  padding: 10px;
  display: flex;
  gap: 10px;
}

.signup-section .signup .user-role-select label input {
  accent-color: var(--sh-primary);
}

.signup-section .signup .signup-with-social {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.signup-section .signup .signup-with-social button {
  border: 1px solid var(--sh-primary-middle);
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 5px 8px;
}

.signup-section .signup .signup-with-social .v-line {
  height: 25px;
  width: 1px;
  background: #808080ad;
  margin-inline: 26px;
}

.signup-section .signup-division {
  position: relative;
  width: 100%;
  display: flex;
  margin-inline: auto;
  justify-content: center;
}

.signup-section .signup-division hr {
  width: 60%;
}

.signup-section .signup-division span {
  position: absolute;
  left: 45%;
  top: 21%;
  background: #fff;
  padding-inline: 12px;
  font-size: 0.8rem;
  color: #808080;
}

/* 
.track-order {
  background: url(../../../assets/image/settingsBG/TrackYourOrder1.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 50%;
} */

.track-order img {
  width: 63%;
  margin-inline: auto;
}