.settings-page-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.main-container {
    margin-bottom: 30px;
    position: relative;
}

.main-container .tile {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    min-height: 325px;
    cursor: pointer;
    /* overflow: hidden; */
    transition: 0.2s;
    border-bottom: 3px solid var(--sh-primary);
}

.main-container:hover .tile {
    border-bottom: 10px solid var(--sh-primary);
}

.main-container img {
    position: absolute;
    opacity: 0.4;
    bottom: 0;
    right: 11px;
    height: 12rem;
}

/* .main-container .tile::before {
    position: absolute;
    content: "";
    height: 272%;
    top: -191px;
    right: 84px;
    background: rgb(25, 117, 201);
    background: linear-gradient(90deg, rgba(25, 117, 201, 1) 0%, rgba(96, 169, 235, 1) 80%, rgba(197, 220, 241, 1) 100%);
    width: 40%;
    transform: rotate(64deg);
} */

/* .main-container .tile:hover::before {
    animation: border_animate 20s linear infinite;
} */

/* @keyframes border_animate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(3600deg);
    }
} */

/* .main-container .tile::after {
    position: absolute;
    content: '';
    background-color: #fff;
    inset: 5px;
    border-radius: 8px;
} */

.tile-content {
    position: relative;
    z-index: 1;
    padding: 15px;
}

.tile-body ul {
    list-style: none;
    padding-left: 0;
}

.tile-body ul li {
    white-space: nowrap;
    overflow-x: clip;
    line-height: 2rem;
    width: max-content;
}

.icon-container {
    width: 35px;
    display: inline-block;
}

.icon-container svg {
    padding-left: 0;
    transition: 0.5s;
}

.tile-body ul li:hover .icon-container svg {
    animation: iconMove 0.5s linear infinite;
}

@keyframes iconMove {
    from {
        padding-left: 0;
    }

    to {
        padding-left: 18px;
    }

}

.settings-page-container .paid-service {
    padding: 2px 5px;
    font-size: 0.8rem;
    color: var(--sh-primary);
    /* color: #fab005; */
    background: var(--sh-primary-light);
    /* background: #feebc0; */
    border-radius: 0.5rem;
    border: 1px solid var(--sh-primary-middle);
    /* border: 1px solid #fab005; */
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    height: 20px;
    width: 60px;
    justify-content: center;
}

.settings-page-container .paid-service span {
    height: 30px;
}

.change-password-pop {
    position: fixed;
    top: -100%;
    width: 24%;
    left: 38%;
    background: #fff;
    height: 500px;
    z-index: 6;
    transition: 1s;
    border-radius: 0.5rem;
}

.change-password-pop.open {
    top: 10%;
}

.cp-header {
    padding: 20px;
    background: var(--sh-primary-light);
    border-radius: 0.5rem 0.5rem 0 0;
}

.cp-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 69px);
    justify-content: space-between;
}

.cp-body .cp-img-container {
    background: var(--sh-primary-light);
    padding: 32px;
    border-radius: 50%;
    width: fit-content;
    height: auto;
}

.cp-body label {
    width: 100%;
}

.cp-body label input {
    text-align: center;
}

.cp-body button {
    width: 150px;
    text-align: center;
}

.whatsapp-comm .action-btn {
    padding: 0px;
    height: 24px;
}