.rae-page {
    height: 100%;
    overflow-y: auto;
    padding-block: 25px;
    padding-inline: 15px;
}

.referal-steps {
    display: flex;
    gap: 10px;
    width: 100%;
}

.step-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
}

.step-content p {
    text-align: center;
}

.step-content .step-image-sec {
    width: 80px;
    height: 80px;
    background-color: var(--sh-primary-light);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}

.rae-invite-sec {
    width: 100%;
    display: flex;
}

.rae-social-sec {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
}

.rae-social-sec label,
.rae-invite-sec label {
    min-width: 500px;
    width: auto;
    position: relative;
}

.rae-invite-sec label input {
    width: 100%;
}

.rae-invite-sec label button {
    position: absolute;
    right: 0;
    height: 100%;
    background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
    color: #fff;
    border: none;
    padding-inline: 20px;
    border-radius: 0 0.5rem 0.5rem 0;
}


label.rae-link-input {
    background: #F1F6FF;
    height: 60px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    min-width: 60%;
    padding-inline: 15px;
}

.rae-link-input input {
    border: none;
    width: 100%;
    background: none;
}

.rae-link-input button {
    color: var(--sh-primary);
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 15px;
}

.rae-link-input button:hover {
    background: var(--sh-primary-light);
    color: var(--sh-primary);
}

.rae-social-btn {
    background: #F1F6FF;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
}

.rae-social-btn:hover {
    background: var(--sh-primary-light);
}