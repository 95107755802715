.zone-mapping-container {
    position: fixed;
    width: 50%;
    top: -250px;
    left: 25%;
    background-color: #fff;
    padding: 15px;
    z-index: 6;
    border-radius: 0.5rem;
    transition: 1s;
}

.zone-mapping-container.open {
    top: 10%;
}

.rotate-180 {
    transform: rotate(180deg);
    display: inline-block;
}