.new-scheduler-slider {
    position: fixed;
    top: 0;
    right: -95rem;
    transition: 1s;
    height: 100vh;
    width: 87rem;
    background-color: #fff;
    z-index: 6;
}

.new-scheduler-slider.open {
    right: 0;
}

.new-scheduler-slider .ticket-slider-body {
    height: 100%;
}

/* Define the grid container */
.grid-container {
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    height: calc(100vh - 178px);
    padding-bottom: 70px;
}

/* Style for grid items */
.grid-item {
    padding: 20px;
    border: 1px solid;
    border-radius: 0.5rem;
}

/* Make Component 1 span first column */
.component-1 {
    grid-row: 1;
    grid-column: 1;
}

/* Make Component 2 span second column and both rows */
.component-2 {
    grid-row: 1 / span 2;
    grid-column: 2;
}

/* Make Component 3 span first column */
.component-3 {
    grid-row: 2;
    grid-column: 1;
}

.new-scheduler-slider .react-multi-email {
    border-color: var(--sh-primary-middle);
    border-radius: 0.5rem;
    padding-block: 2px;
}

.new-scheduler-slider .react-multi-email.empty {
    overflow: hidden;
}

.new-scheduler-slider .react-multi-email.empty>span[data-placeholder] {
    font-size: 1rem;
    color: inherit;
    padding: 0;
    padding-top: 1px;
    padding-left: 3px;
    white-space: nowrap;
}

.new-scheduler-slider .css-13cymwt-control,
.new-scheduler-slider .css-t3ipsp-control,
.new-scheduler-slider .css-t3ipsp-control:hover {
    border-color: var(--sh-primary-middle);
    border-radius: 0.5rem;
    border-width: 1px;
    box-shadow: none;
}

.new-scheduler-slider .css-b62m3t-container:focus-visible {
    outline: none;
}

.rs-page-container .table-container {
    height: calc(100vh - 189px);
    overflow-y: auto;
}

@media screen and (min-width:1601px) {
    .rs-page-container .table-container {
        height: calc(100vh - 198px);
    }
}