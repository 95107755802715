.pnl-dash .pnl-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    justify-content: center;
    height: calc(70% - -42px);
}

.pnl-income-expense {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.pnl-income-expense .pnl-arrows {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
}

.pnl-income-expense svg {
    transform: rotate(45deg);
    padding: 10px;
    border-radius: 50%;
}