.add-warehouse-section {
    height: calc(100vh - 149px);
    overflow-y: auto;
}

.add-warehouse-section label {
    width: 100%;
    position: relative;
}

.error {
    color: red;
}

.input-field-error {
    border: 1px solid red;
}

.edit-warehouse-section {
    height: 100vh;
    overflow-y: scroll;
}