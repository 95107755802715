.tracking-script-page {
    height: calc(100vh - 118px);
}

.tracking-code-textarea.input-field {
    height: calc(100vh - 312px);
    overflow: auto;
    margin-top: 10px;
}

.copy-feedback {
    top: -24px;
    color: green;
    font-size: 14px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.error-feedback {
    top: -24px;
    color: red;
    font-size: 14px;
}