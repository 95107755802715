.filter-container .css-1nmdiq5-menu {
    z-index: 3;
    color: #111;
}

.table-header-row th,
.table-header-row-second th {
    background-color: #fff;
    padding: 0.5rem;
    border-bottom: none;
    text-align: center;
    vertical-align: bottom;
}

.table-header-row th:first-of-type {
    border-radius: 0.5rem 0 0 0.5rem;
}

.table-header-row th:last-of-type {
    border-radius: 0 0.5rem 0 0;
}



.table-header-row-second th {
    border-top: 1px solid #ddd;
}

.nested-td {
    border-radius: none;
}

.nested-td td {
    text-align: center;
}

.nested-td td:first-of-type {
    text-align: left;
}

.nested-tr td {
    border: 1px solid #ddd;
    text-align: center;
    font-size: 14px;
}

.nested-tr .rowfull3 {
    border-left: none;
    /* border-right: none; */
    border-top: none;
    border-bottom: none;
}

.rate-calc-page section.rate-des label {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    position: relative;
    width: 100%;
    max-width: 350px;
    position: relative;
    height: fit-content;
}

.rate-calc-page p {
    margin-bottom: 0;
}

.rate-calc-page label span.unit {
    position: absolute;
    right: 1px;
    bottom: 1px;
    height: 34px;
    width: 36px;
    background: var(--sh-primary-light);
    background: #dfdfdf;
    border-radius: 0 0.5rem 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
}



.rate-calc-page .css-1v5z18m {
    width: 100%;
}

.rate-calc-page .css-7d61z1-MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
    border: 1px solid var(--sh-primary);
    font-weight: 600;
    background-color: #fff;
    color: var(--sh-primary);
}

.rate-calc-page .css-7d61z1-MuiSlider-valueLabel {
    top: -15px;
}


.rate-calc-page .css-7d61z1-MuiSlider-valueLabel::before {
    width: 13px;
    height: 13px;
    border-right: 1px solid;
    border-bottom: 1px solid;
    bottom: -1px;
}

.rate-calc-page .info-container {
    margin-left: 0.3rem;
    position: relative;
    vertical-align: -3px;
}

.rate-calc-page .info-container .info-hover-show {
    display: none;
    position: absolute;
    font-weight: 400;
    width: 300px;
    left: 32px;
    font-size: 13px;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid var(--sh-primary);
    border-radius: 0.5rem;
    z-index: 1;
    top: -30px;
}

.rate-calc-page .info-container:hover .info-hover-show {
    display: inline-block;
}

.rate-calc-page .question-icon {
    color: var(--sh-primary);
    width: 22px;
    height: 22px;
    font-size: 13px;
    border: 2px solid var(--sh-primary);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.rate-card-page .table-container {
    height: calc(100vh - 230px);
}

.rate-calc-page .css-b62m3t-container {
    width: 100%;
}

td[rowspan]:first-of-type,
td[rowspan]:last-of-type {
    border: none;
}

.rate-calc-page .ship-container-row {
    margin-inline: 10px 0;
}