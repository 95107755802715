.box-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(150px, 1fr));
    /* grid-template-columns: repeat(4, 22%); */
    /* Each row contains 4 boxes */
    gap: 50px;
    /* Gap between grid items */
    max-height: calc(100vh - 201px);
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.box {
    /* padding: 20px; */
    /* border-radius: 5px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    margin-bottom: 5px;
    /* Space between rows */
    transition: 400ms;
    /* transform: scale(1); */
    perspective: 1000px;
    background-color: transparent;
    min-height: 25.18rem;
    perspective: 1000px;
    color: white;
    transition-timing-function: linear;
    /* transform-style: preserve-3d; */
}

.box:hover {
    /* transform: scale(1.03) */
    box-shadow: 0px 0px 72px -36px rgb(19 20 21);
}


.box-card-outer {
    position: relative;
    /* text-align: center; */
    transition: transform 0.8s;
    transform-style: preserve-3d;
    display: flex;
    min-height: 24.43rem;
}

.warehouse-details,
.rto-details {
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    display: flex;
    width: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 1rem;
    flex: 1;
    padding: 10px;
    min-height: 25.18rem;
    justify-content: space-between;
}

.warehouse-details p,
.rto-details p {
    margin-bottom: 0px;
}

.warehouse-details hr,
.rto-details hr {
    margin-block: 5px;
}

.warehouse-details {
    color: #111;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -1px 0px inset;
}

.rto-details {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -1px 0px inset;
    background-color: #98bce6;
    transform: rotateY(180deg);
}

.box .box-card-outer.card-flip {
    transform: rotateY(180deg);
}

.box-card-outer .warehouse-heading {
    display: flex;
    align-items: flex-start;
    font-size: 1.5rem;
    font-weight: 500;
    gap: 10px;
    max-width: unset;
}

.box-card-outer .warehouse-heading h4 {
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rto-details .warehouse-heading {
    color: #fff;
}

.rto-details .close-button {
    width: 30px;
    height: 30px;
    text-align: end;
    color: #fff;
    font-size: 1.5rem;
    position: absolute;
    right: 18px;
    top: -2px;
}

.rto-pin-title {
    font-size: 0.8rem;
    background-color: var(--sh-primary);
    border-radius: 0.5rem;
    padding: 2px 10px;
    width: fit-content;
    margin-bottom: 0.625rem;
}

.mark-as-default-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 9;
    font-size: 0.8rem;
    background-color: #ddd;

}

.mark-as-default-btn:hover {
    background-color: var(--sh-primary);
    color: #fff;
}

.mark-as-default-btn.bg-green:hover,
.mark-as-default-btn.bg-green:active {
    background-color: green !important;
    color: #fff;
}

.manage-warehouse .search-container label button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none;
    cursor: pointer;
    background: linear-gradient(180deg, var(--sh-primary-light) 0%, var(--sh-primary-middle) 0%, var(--sh-primary) 100%);
    color: #fff;
    width: 36px;
    height: 36px;
    border-radius: 0 0.5rem 0.5rem 0;
}

.manage-warehouse .search-container .input-field {
    padding-left: 10px;
}

.warehouse-edit.open {
    width: 44em;
}


.warehouse-edit .ticket-slider-footer {
    padding-inline: 30px 20px;
    display: flex;
    justify-content: flex-end;
}

/* .warehouse-edit .add-warehouse-section .d-flex.justify-content-end.my-3 button {
    display: none;
} */

.warehouse-edit .add-warehouse-section {
    box-shadow: none !important;
    padding: 0;
    height: calc(100vh - 175px);
}



.ticket-slider-body .mobile-number-field input.input-field {
    border-radius: 0.5rem;
    padding-left: 72px;
}

.ticket-slider-body .mobile-number-field select.input-field {
    width: 60px;
    min-width: 60px;
}

.bulk-import-modal .modal-header {
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--sh-primary-light);
}

.bulk-import-modal .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bulk-import-modal .btn-close {
    display: none;
}

.bulk-import-modal .modal-header .modal-title {
    line-height: normal;
}

.bulk-import-modal .modal-content {
    min-height: 290px;
}

.warehouse-edit.open .custom-error {
    left: 0;
}

@media (max-width: 768px) {
    .box-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        /* Adjust column width for smaller screens */
    }
}