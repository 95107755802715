.stepper-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepper-form-container .steps-header {
    position: relative;
}

.stepper-form-container .progress-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}


/* Style the progress bar */
/* progress {
    width: 100%;
} */

.stepper-form-container .progress-bar {
    background-color: var(--sh-primary);
    height: 4px;
}


.stepper-form-container .stepper-line {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.stepper-form-container .step-marker span {
    border: 1px solid;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.stepper-form-container .step-marker span.completed {
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, var(--sh-primary), var(--sh-primary-light)) border-box;
    border-radius: 50em;
    border: 2px solid transparent
}

.inputs-container {
    width: 50%;
    transition: 0.5s;
}

.inputs-container label {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.3rem;
    /* width: fit-content;
    max-width: fit-content; */
}

.inputs-container .toggle-label {
    max-width: unset;
}

.toggle-switch label {
    position: relative;
    display: inline-block;
    width: 47px;
    height: 23px;
    max-width: unset;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--sh-primary-light);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.toggle-switch .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.toggle-switch input:checked+.slider {
    background-color: var(--sh-primary);
}

.toggle-switch input:focus+.slider {
    box-shadow: 0 0 1px var(--sh-primary);
}

.toggle-switch input:checked+.slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}

.warehouse-options label {
    flex-direction: row;
    gap: 10px;
    border-radius: 16px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .102);
    border: solid 1.3px #afcfff;
    background-color: #f4f8ff;
    min-height: 12rem;
    padding: 1em;
    height: 100%;
}

.warehouse-options input {
    margin-top: 6px;
}

.warehouse-heading {
    color: #191919;
    font-size: 14px;
    line-height: 27px;
    letter-spacing: .24px;
    text-transform: capitalize;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.warehouse-description {
    font-size: 11px;
    line-height: 1.45;
    letter-spacing: .22px;
    color: #4c4c4c;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
}

.form-box-h {
    height: calc(100vh - 299px);
    overflow-y: auto;
}

.form-box-h hr {
    border: none;
    /* border-top: 3px solid #ddd; */
    color: #ddd;
    color: var(--sh-primary);
    overflow: visible;
    text-align: center;
    height: 1px;
    margin-block: 2rem;
}

/* hr::after {
    background: #fff;
    content: '§';
    padding: 0 4px;
    position: relative;
    top: -13px;
} */

.react-datepicker-wrapper {
    width: 100%;
}


.mobile-number-field {
    position: relative;
    width: 100%;
}

.mobile-number-field select.input-field {
    width: 70px;
    min-width: 70px;
    border-radius: 0.5rem 0 0 0.5rem;
    border: none;
    position: absolute;
    background-color: transparent;
}

.mobile-number-field input.input-field {
    /* border-radius: 0 0.5rem 0.5rem 0; */
    padding-left: 80px;
}

.date-picker-container {
    position: relative;
    width: 100%;
}

.calendar-icon {
    top: 49%;
    left: -1px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #777;
    z-index: 1;
}

.add-fields-text {
    color: var(--sh-primary);
    cursor: pointer;
    width: fit-content;
}

.optional-fields {
    overflow: hidden;
    /* transition: max-height 2s ease-in; */
    transition: transform 0.2s ease-in;
    transform: scaleY(0);
    height: auto;
}

.optional-fields.open {
    transform: scaleY(1);
    transform-origin: top;
}

.height-0 {
    height: 0;
}

.volumetric-weight {
    margin-top: 40px;
    width: 100%;
    font-weight: 700;
    font-size: 18px;
}

.bulk-orders-page {
    min-height: calc(100vh - 180px);
    overflow-y: scroll;
}

.bulk-import-input {
    position: relative;
    width: 60%;
    border: 1px dashed var(--sh-primary);
    min-height: 400px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
}

.bulk-import-input input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}

.bulk-import-input .mid-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bulk-import-input .mid-text-container .accepted-note {
    font-size: 0.8rem;
}

.add-product-onclick {
    color: var(--sh-primary);
    margin-top: -7px;
    cursor: pointer;
}

.upload-click {
    cursor: pointer;
}

.bo-upload-data {
    max-width: 90%;
    margin-top: 2rem;
    margin-inline: auto;
}

.bo-upload-data .bulk-import-table {
    margin-block: 0.5rem;
    overflow-x: auto;
}

.bo-upload-data .bulk-import-table::-webkit-scrollbar {
    height: 1px;
}

.bo-upload-data .bulk-import-table table tbody tr td {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
}


.bo-upload-data table tr th,
.bo-upload-data table tr td {
    padding: 0.7rem;
}

.bo-upload-data tr td,
.bo-upload-data tr th {
    white-space: nowrap;
}

.bo-upload-data tr:nth-of-type(odd) td {
    background-color: var(--sh-primary-light);
}

.bo-upload-data table tr td:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
}

.bo-upload-data table tr td:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
}

.bo-or-text {
    margin-block: 0.5rem 1rem;
    position: relative;
}

.bo-or-text::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 140px;
    background-color: #ddd;
    top: 50%;
    left: 37px;
}

.bo-or-text::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 140px;
    background-color: #ddd;
    top: 50%;
    right: 37px;
}

.create-order-flow-container label {
    position: relative;
}

.create-order-flow-container span.unit {
    align-items: center;
    background: var(--sh-primary-light);
    background: #dfdfdf;
    border-radius: 0 .5rem .5rem 0;
    bottom: 0.08rem;
    display: flex;
    height: 34px;
    justify-content: center;
    position: absolute;
    right: 1px;
    width: 36px;
    font-weight: 400;
    font-size: 1rem;
}

.create-order-flow-container .sku-checkbox {
    position: absolute;
    top: 1px;
    right: 15px;
    font-size: 0.8rem;
}

.inputs-container .css-b62m3t-container {
    width: 100%;
    max-height: 36px;
}

.inputs-container .css-b62m3t-container:focus-visible {
    outline: none;
}

.inputs-container .css-13cymwt-control,
.inputs-container .css-13cymwt-control:hover,
.inputs-container.css-t3ipsp-control,
.inputs-container .css-t3ipsp-control:hover {
    border-color: var(--sh-primary-middle);
    border-radius: 0.5rem;
    box-shadow: none;
}

.inputs-container .css-13cymwt-control {
    height: 36px;
    min-height: unset;
}

/* 
.create-order-flow-container label {
    position: relative;
}

.create-order-flow-container label .custom-error {
    position: absolute;
    bottom: -17px;
    left: 13px;
} */

/* Add responsiveness for smaller screens */

.non-active-flow {
    position: relative;
}

.non-active-flow::after {
    content: "";
    position: absolute;
    background: #00000059;
    width: 100%;
    height: calc(100% - 56px);
    z-index: 3;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
}

.not-active-message {
    background: #fff;
    border-radius: 0.5rem;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 7%;
    position: absolute;
    top: 10%;
    width: 40%;
    height: 55px;
    left: 30%;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.2rem;
}

.mps-inputs {
    display: flex;
    gap: 15px;
}

.cof-btn-container {
    margin-block: 10px;
}

@media screen and (max-width:1500px) {
    .cr-wh-select {
        width: 60%;
    }
}

@media screen and (max-width:1200px) {
    .cr-wh-select {
        width: 73%;
    }
}